<div class="accountLogin__sectionWrapper">

    <div class="accountLogin__userWrapper">
        <img src="../../../assets/Images/login/Users.svg">
        <div class="accountLogin__userText">User Access Control</div>
    </div>

    <div [ngClass]="ibdic ? 'accountLogin__sdexLogo-ibdic' : 'accountLogin__sdexLogo'">
        <img [ngClass]="ibdic ? 'accontLogin__logoImg-ibdic' : ''" class="accontLogin__logoImg"
            src="{{ibdic?'../../../../../assets/ibdic/images/login/IBDIC1.png':'../../../assets/Images/login/logo.svg'}}">
    </div>

    <div [ngClass]="ibdic ? 'accountLogin__PersonalizationWrapper-ibdic' : 'accountLogin__PersonalizationWrapper'">
        <img src="../../../assets/Images/login/settings.svg">
        <div class="accountLogin__userText">Personalization</div>
    </div>

    <div [ngClass]="ibdic ? 'accountLogin__DataWrapper-ibdic' : 'accountLogin__DataWrapper'">
        <img src="../../../assets/Images/login/securityData.svg">
        <div class="accountLogin__userText">Data Security</div>
    </div>

    <div [ngClass]="ibdic ? 'accountLogin__workWrapper-ibdic' : 'accountLogin__workWrapper'">
        <img src="../../../assets/Images/login/dataWorkflow.svg">
        <div class="accountLogin__userText">Seamless Workflows</div>
    </div>

    <div [ngClass]="ibdic ? 'accountLogin__transfersWrapper-ibdic' : 'accountLogin__transfersWrapper'">
        <img src="../../../assets/Images/login/transfers.svg">
        <div class="accountLogin__userText">Faster Transfers</div>
    </div>

</div>