<div class="accountLogin__bodySection">
<div class="accountLogin__body">
  <div class="accountLogin__section">
    <div class="accountLogin__logo" *ngIf="!ibdic">
      <img src="../../../assets/Images/login/headerLogo.svg">
      <img src="../../../assets/Images/login/sdexLogo.svg">
    </div>
    <div class="accountLogin__logo" *ngIf="ibdic">
      <img class="ibdic-logo" src="../../../assets/ibdic/images/login/IBDIC1.png">
      <img class="ibdic-name" src="../../../assets/ibdic/images/login/ibdic2.png">
    </div>
    <form (ngSubmit)="onLogin()" name="loginForm" [formGroup]="loginForm">
      <div class="accountLogin__signIn">
        <div class="accountLogin__welcome">Welcome to</div>
        <div class="accountLogin__welcomeHeader">{{ibdic?'Indian Banks\' Digital Infrastructure Company' :'Secured Document Exchange'}}</div>
        <div class="accountLogin__inputWrapper">
          <div class="accountLogin__inputSection">
            <div class="accountLogin__inputLabel">Email</div>
            <div [ngStyle]="ibdic?{'width': '400px'} : {}">
              <app-input-filed [control]="$any(loginForm).controls['username']"> </app-input-filed>
            </div>
          </div>
          
          <!-- new code one -->
          <div class="accountLogin__inputSection">
            <div class="accountLogin__inputLabel"> Password</div>

            <div class="usersFilter__branchesField">
              <div class="usersFilter__branchWrappar">
                <input [type]="'password'" id="password" class="usersFilter__input overrideInputfield" style="
              border: none !important;
              outline: none !important;
              box-shadow: none !important;
              border-color: #000 !important;
              width: 320px;
            " type="{{ hide ? 'password' : 'text' }}" formControlName="password" />
                <fa-icon class="usersFilter__searchIcon" [icon]="hide ? faEye : faEyeSlash" (click)="hide = !hide"
                  [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hide"></fa-icon>
              </div>
            </div>
           
          </div>
          <!-- new code one -->

        </div>
        <div class="accountLogin__forgotPassword">
          <div class="accountLogin__remember">
           
            <a style="text-decoration: none;" [routerLink]="['/setup-account']" class="text-link">
              <div class="accountLogin__rememberText">Setup Password</div>
            </a>
          </div>
          <a style="text-decoration: none;" [routerLink]="['/forgot-password']">
            <div class="accountLogin__passwordText">Forgot Password ?</div>
          </a>
        </div>
        <div class="accountLogin__button">
          <button class="accountLogin__signInButton" [disabled]="!loginForm.valid">Sign In</button>
        </div>
        <div class="mt-2 text-center text-danger pb-3">
          {{errorLogin}}
        </div>
        <div class="accountLogin__signUpWrapper">

        </div>
      </div>
    </form>
  </div>
  <app-logo [ngClass]="ibdic ? 'ibdic-logo-sec' : ''"></app-logo>
 
</div>
</div>