import { Directive, ElementRef, Input } from '@angular/core';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { environment } from 'src/environments/environment';
import { StorageKeys } from './share/enums/storage.enum';

@Directive({
  selector: '[appAccessControl]',
})
export class AccessControlDirective {
  @Input('allowedRoles') allowedRoles: string;
  @Input('restrictedRoles') restrictedRoles: string;

  constructor(
    private elementRef: ElementRef,
    private storageService: StorageService
  ) {}
  ngOnInit() {
    if(!environment.rolesDisabled){
      this.elementRef.nativeElement.style.display = 'none';
      this.checkAccess();
    }
  }

  checkHasRole(userRoles: string, selfDetails: any){
    let hasAccess = false;
    let allowedRoles = (userRoles || '').split(',');
    for (let k = 0; k < allowedRoles.length; k++) {
      let moduleType = allowedRoles[k].split('.')[0];
      let accessType = allowedRoles[k].split('.')[1];
      for (
        let i = 0;
        i < ((selfDetails || {}).presentRoles || []).length;
        i++
      ) {
        for (
          let j = 0;
          j < (selfDetails.presentRoles[i].permissions || []).length;
          j++
        ) {
          if (
            selfDetails.presentRoles[i].permissions[j].module === moduleType
          ) {
            if (
              (selfDetails.presentRoles[i].permissions[j].subPermissions || {})[
                accessType
                ]
            ) {
              hasAccess = true;
            } else if (selfDetails.presentRoles[i].permissions[j].enabled) {
              hasAccess = true;
            }else if(accessType === 'view' && (Object.keys(selfDetails.presentRoles[i].permissions[j].subPermissions || {}).length)){
              hasAccess = true;
            }
          }
        }
      }
    }
    return hasAccess;
  }
  checkAccess() {
    const selfDetails =
      this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    let hasAccess = false;
    let enrollmentType = selfDetails.enrollmentType;
    if (enrollmentType == 'Admin') {
      hasAccess = true;
    }
    let isAllowedRole = this.checkHasRole(this.allowedRoles || '', selfDetails);
    let isRestrictedRole = this.checkHasRole(this.restrictedRoles || '', selfDetails);
    if(isAllowedRole && isRestrictedRole){
      hasAccess = false;
    }else if(isAllowedRole){
      hasAccess = true;
    }else if (selfDetails.primary || selfDetails.isAdmin) {
      hasAccess = !(this.allowedRoles && this.restrictedRoles);
    }
    if (hasAccess) {
      this.elementRef.nativeElement.style.display = 'inherit';
    } else {
      this.elementRef.nativeElement.style.display = 'none';
    }
 
  }
  
}
