import { Component } from '@angular/core';
import { Input } from '@angular/core';
@Component({
  selector: 'app-bulk-list-common',
  templateUrl: './bulk-list-common.component.html',
  styleUrls: ['./bulk-list-common.component.scss']
})
export class BulkListCommonComponent {

  @Input() bulktableData:any;

}
