<div class="modal-header zindex-modal">
    <h4 class="modal-title" id="modal-basic-title">
       {{ header ? header :'Confirm'}}
    </h4>
    <div (click)="activeModal.dismiss(false)" class="close"><img
            src="../../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon"></div>
</div>
<div class="modal-body">
    <div>
        <h4>{{message}}</h4>
    </div>
</div>
<div class="modal-footer">
    <div class="text-right">
        <div class="d-flex justify-content-end">
            <app-common-button class="m-1" buttonClass="smallbutton" buttonLabel="Close"
                (click)="activeModal.close(false)">
            </app-common-button>
            <app-common-button class="m-1" buttonClass="smallFilledButton" buttonLabel="Confirm"
                (click)="activeModal.close(true)">
            </app-common-button>
        </div>
    </div>
</div>