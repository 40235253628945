import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SdexAdminService } from 'src/app/share/services/sdex/sdex-admin.service';
import { StorageKeys } from '../../../share/enums/storage.enum';
import { StorageService } from '../../../share/services/storage-service/storage.service';

@Component({
  selector: 'app-blockchain-key-generarte-and-verify',
  templateUrl: './blockchain-key-generarte-and-verify.component.html',
  styleUrls: ['./blockchain-key-generarte-and-verify.component.scss'],
})

export class BlockchainKeyGenerarteAndVerifyComponent implements OnInit, OnChanges {
  @Input() blockChainkeyGeneratedData : any;
  @Output() closePanel                : EventEmitter<void> = new EventEmitter<void>();
  @Output() closeBlockChainPanel      : EventEmitter<void> = new EventEmitter<void>();
  @Output() blockchainKeyGenerated    : EventEmitter<void> = new EventEmitter<void>();
  keydata                             : any;
  errorMsg                            : any;
  userDetailsData                     : any = {};
  showSuccessBlockchainMsg            : boolean = true;
  loadingIcon                         : boolean = false;

  constructor(
    private _service: SdexAdminService,
    private router: Router,
    private storageService: StorageService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.blockChainkeyGeneratedData?.keyGenerated) {
      this.showSuccessBlockchainMsg = false;
    }
  }

  ngOnInit(): void {
    this.userDetailsData = this.storageService.getDataFromLocalStorage(
      StorageKeys.SELF_DETAILS
    );
  }

  createBlockchain() {
    this.loadingIcon = true;
    this._service
      .createBlockchain(this.userDetailsData?.name)
      .subscribe((res: any) => {
        if (res) {
          this.keydata = res.data?.message;
          this.showSuccessBlockchainMsg = false;
          this.closeBlockChainPanel.emit();
          this.blockchainKeyGenerated.emit();
        }
        this.loadingIcon = false;
      }, (error) => {
        this.errorMsg = error?.error?.error?.message;
        this.loadingIcon = false;
      });
  }

  closeOffcanvas() {
    this.closeBlockChainPanel.emit();
  }

  onCloseIconClick() {
    document.body.classList.remove('panel-open');
    this.closePanel.emit();
  }

}
