<div class="filterOffcanvas__body">
    <div class="filterOffcanvas__bodySection">
    
    <div class="usersFilter__header">
        <div class="usersFilter__headerText">Filters</div>
        <div class="usersFilter__headerImages">
            <img  class="usersFilter__reload" src="../../../../assets/Images/user-management/reload.svg">
            <div class="usersFilter__border"></div>
            <img (click)="onCloseIconClick()" class="usersFilter__crossIcon" src="../../../../assets/Images/user-management/Cross.svg">
        </div>
    </div>
    <div class="filterOffcanvas__Section">
        <div class="filterOffcanvas__inputlabel">Search and Select Networks</div>
        <div class="filterOffcanvas__inputWrapper">
            <img class="filterOffcanvas__searchIcon" src="../../../../assets/Images/package-listing/Search.svg">
            <input class='filterOffcanvas__input' type='text 'placeholder="All Networks">
        </div>
        <div class="filterOffcanvas__selectedData">
        <div class="addUser__dropdownSelectedContent">
            <div class="addUser__dropdownSelectedContentText">Inline Pharma Pvt. Ltd</div>
            <img class="addUser__delete" src="../../../../assets/Images/user-management/delete.svg">
        </div>
        <div class="addUser__dropdownSelectedContent">
            <div class="addUser__dropdownSelectedContentText">TATA Steel Pvt. Ltd</div>
            <img class="addUser__delete" src="../../../../assets/Images/user-management/delete.svg">
        </div>
    </div>
    <div class="filterOffcanvas__inputlabel">Select Branches</div>
    <div class="filterOffcanvas__inputWrapper">
        <img class="filterOffcanvas__searchIcon" src="../../../../assets/Images/package-listing/Search.svg">
        <input class='filterOffcanvas__input' type='text 'placeholder="All Branches">
    </div>
    <div class="filterOffcanvas__checkboxLabel">Select Date Range</div>
    <div class="filterOffcanvas__checkboxWrapper">
        <div class="filterOffcanvas__checkboxes">
    <app-radio-button radioText="Last 7 Days"></app-radio-button>
    <app-radio-button radioText="Last 15 Days"></app-radio-button>
        </div>
        <div class="filterOffcanvas__checkbox">
    <app-radio-button radioText="Last 30 Days"></app-radio-button>
    <app-radio-button radioText="Custom Date Range"></app-radio-button>
        </div>
    </div>
    <div class="filterOffcanvas__inputTextWrapper">
        <div class="filterOffcanvas__inputlabel">From</div>
        <div class="filterOffcanvas__inputlabel">To</div>
    </div>
    <div class="filterOffcanvas__calenderbody">
    <div class="filterOffcanvas__calenderWrapper">
        <p-calendar [(ngModel)]="date" [showIcon]="true" placeholder="16 Jun 2023" dateFormat="dd M yy"></p-calendar>
    </div>
    <div class="filterOffcanvas__calenderBorder"></div>
    <div class="filterOffcanvas__calenderSection">
        <p-calendar [(ngModel)]="date" [showIcon]="true" placeholder="12 Jun 2023" dateFormat="dd M yy"></p-calendar>
    </div>
    </div>
    </div>
    </div>
    <div class="offCanvas__footer">
        <app-common-button buttonClass="smallbutton" buttonLabel="Cancel"></app-common-button>
        <div >
            <app-common-button buttonClass="smallFilledButton" buttonLabel="Apply"></app-common-button>
        </div>
    </div>
</div>
