import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/home/auth/auth.service';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';

@Component({
  selector: 'app-common-steps-card',
  templateUrl: './common-steps-card.component.html',
  styleUrls: ['./common-steps-card.component.scss']
})

export class CommonStepsCardComponent implements OnInit {
  @Input() cardData           : any;
  @Input() esignSelfLink      : any;
  @Input() CreatePackageLink  : any;
  @Output() openNextStep      : EventEmitter<void> = new EventEmitter<void>();
  enrollmentType              : any;
  isdisabled                  : any;
  isAdmin                     : boolean;
  isDisabled                  : boolean;

  constructor(
    private router         : Router,
    private storageService : StorageService,
    public authService     : AuthService) { }

  ngOnInit(): void {
    const selfDetails = this.storageService.getDataFromLocalStorage('selfDetails');
    this.enrollmentType = selfDetails.enrollmentType;
    this.isAdmin = !selfDetails.isAdmin;
  }

  onClickEvent(data: any) {
    if (data == 'Verify Identity') {
      this.openNextStep.emit();
    } else if (data == 'Generate Key' || data === 'Generated') {
      if (this.enrollmentType !== 'Admin') {
        this.openNextStep.emit();
      }
    }
    else if (data == 'Add Network') {
      this.router.navigate(['admin/contact-list']);
    }
    else if (data == 'Create Org.Structure') {
      this.router.navigate(['organization-structure']);
    }
    else if (data == 'Define Role') {
      this.router.navigate(['roles/role-list']);
    }
    else if (data == 'Create Package') {
      if (this.enrollmentType !== 'Admin') {
        this.router.navigate(['packages/create-new-package']);
      }
    }
    else if (data == 'Manage Roles') {
      //if (this.enrollmentType !== 'Admin') {
        this.router.navigate(['roles/role-list']);
      //}
    }
    else if (data == 'Manage Users') {
      //if (this.enrollmentType !== 'Admin') {
        this.router.navigate(['admin/sub-company-users']);
      //}
    }
    else if (data == 'Manage Structure') {
      if (this.enrollmentType !== 'Admin') {
        this.router.navigate(['organization-structure']);
      }
    }
    else if (data == 'Corporates') {
      if (this.enrollmentType !== 'Admin') {
        this.router.navigate(['admin/corporates']);
      }
    }
    else if (data == 'Assisted Onboarding') {
      if (this.enrollmentType !== 'Admin') {
        this.router.navigate(['admin/corporates']);
      }
    }
    else if (data == 'Manage Banks') {
      //if (this.enrollmentType !== 'Admin') {
        this.router.navigate(['admin/banks']);
      //}
    }
    else if (data == 'Manage Corporates') {
      //if (this.enrollmentType !== 'Admin') {
        this.router.navigate(['admin/sdex/company']);
      //}
    }
  }
  
}
