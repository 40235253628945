<div class="userDetails__body">
  <div class="userDetails__headerWrapper">
    <div class="userDetails__header">
      <div class="userDetails__headerText">User details </div>
      <div class="userDetails__headerImages">
        <ng-container *ngIf="userId !== parentData?._id">
          <img appAccessControl allowedRoles="user.Edit" class="userDetails__crossIcon" (click)="handleEditUser()"
            src="../../../../assets/Images/user-management/edit.svg" />
        </ng-container>
        <ng-container *ngIf="
            userId !== parentData?._id &&
            parentData?.rootCompany !== parentData?.company?._id &&
            parentData.primary
          ">
          <div class="userDetails__border"></div>
          <img appAccessControl allowedRoles="user.Disable" (click)="deleteUser(parentData?._id)"
            class="userDetails__crossIcon" src="../../../../assets/Images/user-management/trash.svg" />
        </ng-container>
        <div class="userDetails__border"></div>
        <img (click)="onCloseIconClick()" class="userDetails__crossIcon"
          src="../../../../assets/Images/user-management/Cross.svg" />
      </div>
    </div>
  </div>
  <div class="userDetails__basicdetailsWrapper">
    <div ngClass="userDetails__basicdetailsSection">
      <div class="userDetails__subText">Basic details:</div>
      <div>
        <div class="userDetails__nameheader">Name</div>
        <div class="userDetails__name">{{ parentData?.firstName }} {{ parentData?.middleName }} {{ parentData?.lastName }}</div>
      </div>
      <div>
        <div class="userDetails__nameheader">Unique Id</div>
        <div class="userDetails__name">{{ parentData?.uniqueId }}</div>
      </div>
      <div>
        <div class="userDetails__nameheader">Email</div>
        <div class="userDetails__name">{{ parentData?.emailAddress }}</div>
      </div>
      <div class="userDetails__mobileDetails">
        <div class="userDetails__nameheader">Mobile Number</div>
        <div class="userDetails__name">{{ parentData?.mobileNumber }}</div>
      </div>
    </div>
  </div>
  <div class="userDetails__basicdetailsWrapper">
    <div [ngClass]="isIbdic? 'ibdic_userDetails__basicdetailsSection' : 'userDetails__basicdetailsSection' ">
      <div class="userDetails__subText">Branches assigned:</div>
      <ng-container *ngFor="let branch of parentData?.assignedTo">
        <div class="assigned_block">
          <div class="d-flex trow">
            <div class="tcolumn">
              <p class="userDetails__nameheader">Branch</p>
            </div>
            <div class="tcolumn">
              <p class="userDetails__nameheader">Roles</p>
            </div>
          </div>
          <div>
            <div class="d-flex trow1">
              <div class="tcolumn">
                <p class="userDetails__name">{{ branch?.company?.name | uppercase }}</p>
              </div>
              <div class="tcolumn" *ngIf="branch.isAdmin">
                <p class="userDetails__name">Admin{{ " " }}</p>
              </div>
              <ng-container *ngFor="let role of branch.roles">
                <div class="tcolumn">
                  <p class="userDetails__name">{{ role?.name }} {{ " " }}</p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="userDetails__Section" *ngIf="!isIbdic">
    <div class="userDetails__verification">
      <div class="userDetails__subText">Verification status</div>
      <div class="userDetails__verificationDetails">
        <div class="row">
          <div class="col-lg-6">
            <div class="userDetails__nameheader">E-Sign</div>
            <div class="userDetails__verificationstatus">
              <div class="userDetails__verificationFieldStatus" *ngIf="parentData?.eSignedAt">
                <img src="../../../../assets/Images/user-management/Verified.svg" />
                <div class="userDetails__verificationFieldText">Verified</div>
              </div>
              <div class="userDetails__verificationFieldStatus" *ngIf="!parentData?.eSignedAt">
                <img src="../../../../assets/Images/user-management/Unverified.svg" />
                <div class="userDetails__verificationUnverified">Unverified</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="userDetails__nameheader">BlockchainKey</div>
            <div class="userDetails__verificationstatus">
              <div class="userDetails__verificationFieldStatus" *ngIf="parentData.isKeyGenerated">
                <img src="../../../../assets/Images/user-management/Verified.svg" />
                <div class="userDetails__verificationFieldText">Verified</div>
              </div>
              <div class="userDetails__verificationFieldStatus" *ngIf="!parentData.isKeyGenerated">
                <img src="../../../../assets/Images/user-management/Unverified.svg" />
                <div class="userDetails__verificationUnverified">Unverified</div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  </div>
</div>
