<div class="addedFiles__card" [ngClass]="{'blue':addedFilesData?.data?.selectedDoc?.CardColor==='blue','violet':addedFilesData?.data?.selectedDoc?.CardColor==='violet','pink':addedFilesData?.data.selectedDoc?.CardColor==='pink'}">
  <div [ngClass]="addedFilesData?.data?.selectedDoc?.CardColor" class="addedFiles__fileIcon billOfLading">
    {{addedFilesData.data.selectedDocType.tag}}
  </div>
  <div class="addedFiles__fileDetails">
    <div class="addedFiles__fileType">{{addedFilesData?.data?.selectedDoc?.name}}</div>
    <div class="addedFiles__fileName" [ngClass]="{'blueText':addedFilesData?.data?.selectedDoc?.CardColor==='blue','violetText':addedFilesData?.data?.selectedDoc?.CardColor==='violet','pinkText':addedFilesData?.data?.CardColor==='pink'}">{{addedFilesData?.data?.selectedDocType?.name}}</div>
  </div>
  <div class="addedFiles__actionIcons">
    <div (click)="edit(addedFilesData.data)"><img src="../../../../assets/Images/roles-responsibilities/editicon.svg" alt="edit"></div>
    <div (click)="delete()"><img src="../../../../assets/Images/create-package/trash.svg" alt="delete"></div>
  </div>
</div>
