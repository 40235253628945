<section class="my-profile">
  <app-side-nav-bar></app-side-nav-bar>

  <div class="profile-content">
    <!-- Header section of the profile content -->
    <div class="profile__headtext">My Profile</div>
    <div class="profile__managedetails">
      Manage all your profile details here
    </div>

    <!-- About profile below the header -->

    <div class="about-profile">
      <!-- profile image -->

      <div class="about-profile__Image">
        <img
          src="assets\Images\my-profile\irene-strong-v2aKnjMbP_k-unsplash1.svg"
          alt=""
          class="profile_image"
        />
      </div>
      <div class="profile__details">
        <!-- profile name and details-->

        <div class="about-profile__details">
          <div class="proifle__details--Name">Anish Sharma</div>
          <div class="profile__details--teammember">Team Member</div>

          <!-- Email details -->
          <div class="profile__details--Email">Email Address</div>
          <div class="profile__details--emailname">
           {{' anishsharma@tatasteel.com'}}
          </div>

          <!-- Contact details -->
          <label class="profile__details--contact">Contact Number</label><br />
          <input
            type="text"
            class="profile__details--numberinput"
            placeholder="+91 98324 26992"
          /><br />

          <!-- Save button -->
          <button class="profile__details--savebtn">
            <div class="savebtn-text">Save</div>
          </button>
        </div>
      </div>

      <div class="profile__password">
        <div class="prodile__password--Pass">Password</div>

        <div class="profile__password--email">
          Your registered email ID “{{'anishsharma@tatasteel.com'}}” is enabled for
          all your communications.
        </div>

        <div class="profile__password--managepass">Manage Password</div>
        <div class="profile__password--managepass-msg">
          You can change your password by clicking ‘Manage Password’
        </div>
      </div>
    </div>

    <div class="profile-footer">
      <div *ngFor="let i of FooterDetailsData" class="profile-footer__single-container">
        <!-- <app-profile-footer-card [FooterData]="i"></app-profile-footer-card> -->
      </div>
    </div>
  </div>
</section>
