<div class="side-nav-bar__body">
  <div class="side-nav-bar__bodySection">
    <div class="side-nav-bar__logoWrapper" [ngClass]="{ 'side-nav-bar__logoBody': hideText }">
      <div class="side-nav-bar__logo">
        <div class="side-nav-bar__logoSection">
          <img  *ngIf="!ibdic"src="../../../assets/Images/side-nav-bar/sdx__logo.svg" />
          <img  *ngIf="!ibdic"[hidden]="hideText" class="side-nav-bar__icon"
            src="../../../../assets/Images/side-nav-bar/sdex__logo.svg" />
            <img  width="50"src="../../../assets/ibdic/images/login/IBDIC1.png">
            <img  width="100"[hidden]="hideText" class="side-nav-bar__icon"
            src="../../../../assets/ibdic/images/login/ibdic.png" />
            </div>
        </div>
    </div>
    <div class="side-nav-bar__content">
      <div *ngIf=" userDetailsData?.enrollmentType!=='Admin'" class="side-nav-bar__contents pb-3">
        <div [hidden]="hideText" *ngIf="user?.assignedTo?.length > 1">
          <select class="select org-select-dropdown" [(ngModel)]="selectedCompany" (change)="onCompanyChange($event)">
            <option *ngFor="let company of user.assignedTo" [value]="company.company._id">
              {{ company.company.name }}
            </option>
          </select>
        </div>

        <div *ngIf=" userDetailsData?.enrollmentType!=='Admin'" class="side-nav-bar__dashboard"
          [routerLink]="'/admin/dashboard'">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/dashboard"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Dashboard':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Dashboard.svg" />
          <div [hidden]="hideText" class="side-nav-bar__dashboardText">
            Dashboard
          </div>
        </div>
        <div appAccessControl allowedRoles="package.view" *ngIf="!authService.isAdmin() &&!ibdic" class="side-nav-bar__dashboard"
          routerLink="/packages/incoming" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/packages/incoming"></div>
          <div routerLinkActive="side-nav-bar__active" routerLink="/packages/create-new-package"></div>

          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Packages':''" [placement]="'right'"
            container="body" class="side-nav-bar__images" triggers="mouseenter"
            src="../../../assets/Images/side-nav-bar/Packages.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            Packages
          </div>
        </div>
        <div appAccessControl allowedRoles="document.view" *ngIf="!authService.isAdmin() &&!ibdic"
          class="side-nav-bar__dashboard" [routerLink]="'document-vault'">
          <div routerLinkActive="side-nav-bar__active" routerLink="document-vault"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? ' Document Vault':''" [placement]="'right'"
            container="body" triggers="mouseenter" class="side-nav-bar__images"
            src="../../../assets/Images/side-nav-bar/DocumentVoult.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            Document Vault
          </div>
        </div>
        
       </div>
      <div class="side-nav-bar__options">
        <div *ngIf="authService.isAdmin() && ibdic" class="side-nav-bar__dashboard" appAccessControl allowedRoles="reports.view"
          [routerLink]="'/admin/dashboard'">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/dashboard"></div>
          <img (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false" data-bs-toggle="tooltip"
            [ngbTooltip]="showTooltip ? 'Dashboard':''" [placement]="'right'" container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Dashboard.svg" />
          <div [hidden]="hideText" class="side-nav-bar__dashboardText">
            Dashboard
          </div>
        </div>
        <div *ngIf="!authService.isAdmin()" class="side-nav-bar__dashboard" [routerLink]="'/organization-structure'"
          appAccessControl allowedRoles="company.view">
          <div routerLinkActive="side-nav-bar__active" routerLink="organization-structure"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip && ibdic && enrollmentType === 'Bank'? 'Bank Structure':(ibdic && enrollmentType === 'Company'? 'Company Structure' : 'Org. Structure') " [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/OrgStructure.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
         {{ibdic && enrollmentType === 'Bank'? 'Bank' : (ibdic && enrollmentType === 'Company'? 'Org.' : 'Org.')}} Structure
          </div>
        </div>
        <div class="side-nav-bar__dashboard" *ngIf="!rolesDisabled" appAccessControl allowedRoles="role.view"
        [routerLink]="'/roles/role-list'">
        <div routerLinkActive="side-nav-bar__active" routerLink="/roles/role-list"></div>
        <img
          (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
          data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Roles':''" [placement]="'right'"
          container="body" triggers="mouseenter"
          class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Roles.svg" />
        <div [hidden]="hideText" class="side-nav-bar__contentText">Roles</div>
      </div>
        <div appAccessControl allowedRoles="user.view" class="side-nav-bar__dashboard"
          [routerLink]="['admin/sub-company-users']" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/sub-company-users"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Users':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Users.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">Users</div>
        </div>
        <div *ngIf="!authService.isAdmin() && (ibdic && enrollmentType !== 'Bank')" class="side-nav-bar__dashboard" 
          appAccessControl allowedRoles="company.view" [routerLink]="'/admin/corporate-groups'">
            <div routerLinkActive="side-nav-bar__active" routerLink="admin/corporate-groups"></div>
            <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Groups': '' " [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/groups.svg" />
            <div [hidden]="hideText" class="side-nav-bar__contentText">
            Groups & e-BR
            </div>
        </div>
        <div appAccessControl allowedRoles="company-contacts.view" *ngIf="
            !authService.isAdmin() &&
            this.userDetailsData?.company === this.userDetailsData?.rootCompany && (ibdic)
          " class="side-nav-bar__dashboard" [routerLink]="'/admin/contact-list'" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/contact-list"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Network':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Network.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            Network
          </div>
        </div>
        <div *ngIf="!authService.isAdmin() && (ibdic && userDetailsData?.enrollmentType!=='Admin') && userDetailsData?.enrollmentType !='Bank'"
          class="side-nav-bar__dashboard" [routerLink]="'/admin/sdex/banks'">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/sdex/banks"></div>
          <img (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false" data-bs-toggle="tooltip"
            [ngbTooltip]="showTooltip ? 'Banks': '' " [placement]="'right'" container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/bank.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            Banks
          </div>
        </div>
         <div *ngIf="!authService.isAdmin() && (ibdic && enrollmentType === 'Bank')" class="side-nav-bar__dashboard" 
          appAccessControl allowedRoles="company.view" [routerLink]="'/admin/corporates'">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/corporates"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Corporates': '' " [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/company.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
          Corporates
          </div>
         </div>

          <div *ngIf="!authService.isAdmin() && (ibdic && userDetailsData?.enrollmentType !== 'Bank' && userDetailsData?.enrollmentType !== 'Company' )" class="side-nav-bar__dashboard" 
            appAccessControl allowedRoles="company.view" [routerLink]="'/admin/user-mapping'">
            <div routerLinkActive="side-nav-bar__active" routerLink="/admin/user-mapping"></div>
            <img
              (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
              data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'User Mapping': '' " [placement]="'right'"
              container="body" triggers="mouseenter"
              class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Users.svg" />
              <div [hidden]="hideText" class="side-nav-bar__contentText">
                User Mapping
              </div>
          </div>
          <!-- <div *ngIf="!authService.isAdmin() && (ibdic && enrollmentType !== 'Bank')" class="side-nav-bar__dashboard" 
            appAccessControl allowedRoles="company.view" [routerLink]="'/admin/ebrDetails'">
            <div routerLinkActive="side-nav-bar__active" routerLink="/admin/ebrDetails"></div>
            <img
              (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
              data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'e-BR Details': '' " [placement]="'right'"
              container="body" triggers="mouseenter"
              class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/invoice.svg" />
              <div [hidden]="hideText" class="side-nav-bar__contentText">
                e-BR Details
              </div>
          </div> -->
          <!-- allowedRoles="Transactions.view" -->
         <div *ngIf="!authService.isAdmin() " class="side-nav-bar__dashboard" 
            [routerLink]="'/admin/transactions'">
          <div routerLinkActive="side-nav-bar__active" routerLink ="admin/transactions"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Transactions':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/lr-list.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
           Transactions
          </div>
        </div>
        
        <div *ngIf="authService.isAdmin()" class="side-nav-bar__dashboard"
          [routerLink]="'/admin/sdex/bank'" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/sdex/bank"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Banks':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/bank.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            Banks
          </div>
        </div>

         <div *ngIf="authService.isAdmin()" class="side-nav-bar__dashboard" appAccessControl allowedRoles="company.view"
          [routerLink]="'/admin/sdex/company'" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/sdex/company"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip && ibdic ? 'Corporates': (showTooltip && !ibdic ? 'Organisation' : '')" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/company.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            Corporates
          </div>
        </div>
        <div appAccessControl allowedRoles="lr.assign"
          *ngIf="authService.isAdmin() && !ibdic&& userDetailsData?.enrollmentType!=='Bank'" class="side-nav-bar__dashboard"
          [routerLink]="'/admin/admin-templates-list'">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/admin-templates-list"></div>
          <div routerLinkActive="side-nav-bar__active" routerLink="/lr-generation/lr-settings"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'LR Templates':''" [placement]="'right'"
            container="body" triggers="mouseenter" class="side-nav-bar__images img-lr"
            src="../../../assets/Images/side-nav-bar/lr-templates.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            LR Templates
          </div>
        </div>
      </div>

      <div class="side-nav-bar__options">
        <div appAccessControl allowedRoles="lr.template"
          *ngIf="(!authService.isAdmin() && !ibdic &&  userDetailsData?.enrollmentType!=='Bank') && getenrollmentType !=='Bank'" class="side-nav-bar__dashboard"
          [routerLink]="'/admin/company-templates-list'">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/company-templates-list"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'LR Templates':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images img-lr"
            src="../../../assets/Images/side-nav-bar/lr-templates.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            LR Templates
          </div>
        </div>

        <div appAccessControl allowedRoles="lr.create"
          *ngIf="(!authService.isAdmin()  &&!ibdic &&userDetailsData?.enrollmentType!=='Bank') && getenrollmentType !=='Bank'" class="side-nav-bar__dashboard"
          [routerLink]="'/lr-generation/generated-lrs'" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/lr-generation/generated-lrs"></div>
          <div routerLinkActive="side-nav-bar__active" routerLink="/lr-generation"></div>

          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'LR List':''" [placement]="'right'"
            triggers="mouseenter" container="body"
            class="side-nav-bar__images img-lr" src="../../../assets/Images/side-nav-bar/lr-list.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            LR List
          </div>
        </div>

        <div [routerLink]="'/eway-bill/credentials'" appAccessControl allowedRoles="ewaybill.credentials"
             *ngIf="userDetailsData?.company === userDetailsData?.rootCompany && !ibdic" class="side-nav-bar__dashboard">
          <div routerLinkActive="side-nav-bar__active" routerLink="/eway-bill/credentials"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'e-Way Bill':''" [placement]="'right'"
            container="body" class="side-nav-bar__images" triggers="mouseenter"
            src="../../../assets/Images/side-nav-bar/lock.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            e-Way Bill
          </div>
        </div>
        <div [routerLink]="'/iba'" *ngIf=" userDetailsData?.enrollmentType ==='Admin'&& !ibdic" class="side-nav-bar__dashboard">
          <div routerLinkActive="side-nav-bar__active" routerLink="/iba"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'IBA':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/bank.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            IBA
          </div>
        </div>
        <div [routerLink]="'/einvoice'" appAccessControl allowedRoles="einvoice.credentials"
             *ngIf="userDetailsData?.company === userDetailsData?.rootCompany && !ibdic" class="side-nav-bar__dashboard">
          <div routerLinkActive="side-nav-bar__active" routerLink="/einvoice"></div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'e-Invoice':''" [placement]="'right'"
            container="body"
            triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/invoice.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            e-Invoice
          </div>
        </div>
        <!-- <div *ngIf="!authService.isAdmin() || ibdic" class="side-nav-bar__dashboard" appAccessControl allowedRoles="reports.view" 
          (click)="hideListText()" [routerLink]="'/admin/notification'">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/notification">
          </div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Notifications':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/notification.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
           Notifications
          </div>
        </div> -->

        <div *ngIf="!authService.isAdmin() || ibdic" class="side-nav-bar__dashboard" appAccessControl allowedRoles="reports.view" 
         [routerLink]="'/admin/reports-and-mis'" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/reports-and-mis">
          </div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Reports and MIS':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/reports.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
           Reports and MIS
          </div>
        </div>

        <div *ngIf="authService.isAdmin() && ibdic" class="side-nav-bar__dashboard" appAccessControl allowedRoles="reports.view" 
         [routerLink]="'/admin/reciepts-and-payments'" (click)="hideListText()">
         <div routerLinkActive="side-nav-bar__active" routerLink="/admin/reciepts-and-payments">
          </div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Reciepts & Payments':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/payments.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
           Reciepts & Payments
          </div>
        </div>
        <div *ngIf="ibdic" class="side-nav-bar__dashboard" 
          [routerLink]="'/admin/notifications'" (click)="hideListText()">
          <div routerLinkActive="side-nav-bar__active" routerLink="/admin/notifications">
          </div>
          <img
            (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
            data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Notifications':''" [placement]="'right'"
            container="body" triggers="mouseenter"
            class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/notification.svg" />
          <div [hidden]="hideText" class="side-nav-bar__contentText">
            Notifications
          </div>
        </div>
        <div [routerLink]="'/admin/company-settings'" class="side-nav-bar__dashboard" *ngIf="
        userDetailsData?.primary && 
        userDetailsData?.company === userDetailsData?.rootCompany || ibdic">
        <div routerLinkActive="side-nav-bar__active hover-fix" routerLink="/admin/company-settings"></div>
        <img
          (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
          data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Global Settings':''" [placement]="'right'"
          container="body" triggers="mouseenter" class="side-nav-bar__images"
          src="../../../assets/Images/side-nav-bar/settingsImage.svg" />
        <div [hidden]="hideText" class="side-nav-bar__contentText">
          Global Settings
        </div>
      </div>
      </div>
      <div>
        <button [ngClass]="{
            'side-nav-bar__button': buttonText === '<',
            'side-nav-bar__toggleButton': buttonText === '>'
          }" (click)="toggleTextVisibility()" class="side-nav-bar__button">
          <img *ngIf="!hideText" src="../../../assets/Images/side-nav-bar/closeArrow.svg" alt="Toggle Button" />
          <img *ngIf="hideText" src="../../../assets/Images/side-nav-bar/openArrow.svg" alt="open arrow" />
        </button>
      </div>
      <div [ngClass]="{
          'side-nav-bar__userWrapper': buttonText === '<',
          'side-nav-bar__userSection': buttonText === '>'
        }" class="side-nav-bar__userWrapper">
        <div [ngClass]="{
            'side-nav-bar__user': buttonText === '<',
            'side-nav-bar__admin': buttonText === '>'
          }" class="side-nav-bar__user">
          <div routerLinkActive="side-nav-bar__active" routerLink="/user-setting/user-profile"></div>
          <img *ngIf="profilePic" class="side-nav-bar__userImage cursor-pointer profimg" [src]="profilePic"
            [routerLink]="'/user-setting/user-profile'" data-bs-toggle="tooltip"
            [ngbTooltip]="(showTooltip && userDetailsData?.name)?userDetailsData?.name:''" [placement]="'right'"
            container="body" />
          <fa-icon *ngIf="!profilePic" [icon]="userCircleIcon" [routerLink]="'/user-setting/user-profile'"
            class="cursor-pointer side-nav-bar__userImage" style="color: gray; font-size: 40px" data-bs-toggle="tooltip"
            [ngbTooltip]="(showTooltip && userDetailsData?.name)?userDetailsData?.name:''" [placement]="'right'"
            container="body">
          </fa-icon>
          <div [hidden]="hideText" style="padding-left: 20px; padding-top: 30px" class="side-nav-bar__userText">
            {{ userDetailsData?.name }}
          </div>
         
        </div>
        <div [hidden]="hideText" class="side-nav-bar__userText pt-4" (click)="logout()">
          <app-common-button buttonClass="smallFilledButton" buttonLabel="Logout"></app-common-button>
        </div>
        <div [hidden]="!hideText" class="side-nav-bar__userText" (click)="logout()"
          (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
          data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Log Out':''"
          [placement]="'right'" container="body" triggers="mouseenter"
        >
          <fa-icon [icon]="faSignOut" ></fa-icon>
        </div>
      </div>

    </div>

  </div>
</div>
