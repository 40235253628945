import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { RegExpPatterns } from 'src/app/share/enums/regex-pattern.enum';
import { SetupAccountService } from 'src/app/share/services/setup-account.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-setup-account',
  templateUrl: './setup-account.component.html',
  styleUrls: ['./setup-account.component.scss']
})
export class SetupAccountComponent {
  showForm3             : boolean = false;
  showForm4             : boolean = true;
  showForm5             : boolean = false;
  hide                  : boolean = true;
  hide2                 : boolean = true;
  ifReg                 : boolean = true;
  disabled              : boolean = true;
  welRegHeader          : boolean = true;
  subRegHeader          : boolean = true;
  ibdic                 : boolean = false;
  companyNamevalue      : any;
  registrationTypevalue : any;
  emailAddressvalue     : any;
  namevalue             : any;
  secondaryUserEmail    : any;
  errorMsg              : any;
  errMsg                : any;

  protected readonly faEye = faEye;
  protected readonly faEyeSlash = faEyeSlash;

  constructor(
    private fb: FormBuilder,
    private setupAccountService: SetupAccountService
  ) {  }

  ngOnInit(): void {
    this.ibdic = environment.ibdic;
    this.welRegHeader = true;
    this.subRegHeader = true;
    if (this.ibdic && this.showForm5) {
      this.registerForm5.get('email')?.addValidators(Validators.required);
    }
    if (this.ibdic && this.showForm4) {
      this.secondaryForm.get('mobileNumber')?.addValidators(Validators.required);
    }
  }

  secondaryForm: FormGroup = this.fb.group({
    emailAddress: ['', [Validators.required, Validators.pattern(RegExpPatterns.EmailPattern)]],
    mobileNumber:['']
  })

  registerForm5: FormGroup = this.fb.group({
    otp: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.pattern(RegExpPatterns.PasswordPattern)]],
    repassword: ['', [Validators.required]],
    email: [''],
  }, {
    validator: this.passwordsMatchValidator
  });

  passwordsMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;
    if (password !== confirmPassword) {
      formGroup.get('confirmPassword')?.setErrors({ passwordsMismatch: true });
    } else {
      formGroup.get('confirmPassword')?.setErrors(null);
    }
  }

  verifySecondaryUser() {
    const payload = {
      emailAddress: this.secondaryForm.value.emailAddress
    }
    this.setupAccountService.secondryUserAccountSetup(payload).subscribe(res => {
      this.secondaryUserEmail = this.secondaryForm.controls['emailAddress'].value
      this.showForm4 = false;
      this.showForm5 = true;
      setTimeout(() => {
        this.disabled = false
      }, 900000);
    },
      (error) => {
        this.errorMsg = error?.error?.error?.message;
      }
    )
  }

  resendOTP() {
    this.setupAccountService.secondryUserAccountSetup({ emailAddress: this.secondaryUserEmail }).subscribe(res => {
      this.disabled = true
      setTimeout(() => {
        this.disabled = false
      }, 900000);
    },
      (error) => {
        this.errMsg = error?.error?.error?.message;
      }

    )
  }


  onVerify5() {
    const payload = {
      otp: (this.ibdic && this.showForm5) ? this.registerForm5.value.otp + this.registerForm5.value.email : this.registerForm5.value.otp,
      password: CryptoJS.SHA256(this.registerForm5.value.repassword).toString(CryptoJS.enc.Hex),
      repassword: CryptoJS.SHA256(this.registerForm5.value.repassword).toString(CryptoJS.enc.Hex),
    }

    this.setupAccountService.registerSecondryUser({ ...payload, emailAddress: this.secondaryUserEmail }).subscribe(res => {
      if (res.success) {
        this.showForm5 = false;
        this.showForm3 = true;
      }
    },
      (error) => {
        this.errMsg = error?.error?.error?.message;
      })
  }
}



