<div *ngIf="multi == 'contact'" class="form-control form-select filterOffcanvas__inputlabel">
    <div (mouseleave)="showDropDown = false">
        <button class="drop-toggle btn flat" (click)="showDropdown()">
            <span class="spancolor" *ngIf="checkedList.length<=0">
                Select {{selectedType === "Sent"? "Sent To": selectedType === "Drafts"? "Drafted To": selectedType ===
                "Request"? "Request": "Received From"}}</span>
            <span class="spancolor" *ngIf="checkedList.length>0">{{checkedList.join(', ')}}</span>
        </button>
        <div class="drop-show" *ngIf="showDropDown && list.length>0">
            <div class="usersFilter__branchesField">
                <div class="usersFilter__branchWrappar">
                    <img class="usersFilter__searchIcon" src="../../../../assets/Images/user-management/searchIcon.svg" />
                    <input (input)="handleFilterContact($event)" class="usersFilter__input" placeholder="Search" />
                </div>
            </div>
            <label *ngFor="let item of contactData">
                <app-checkbox checkboxStatus="{{item.checked}}" (checked)="getSelectedValue($event.event.target.checked, item)"></app-checkbox>
                <span>{{item.connectedCompany?.name}}</span>
            </label>
        </div>
    </div>
</div>



<div *ngIf="multi == 'multi'" class="form-control form-select filterOffcanvas__inputlabel">
    <div (mouseleave)="showDropDown = false">
        <button class="drop-toggle btn flat" (click)="showDropdown()">
            <span class="spancolor" *ngIf="checkedMultiList.length<=0">
                Select Document Type</span>
            <span class="spancolor" *ngIf="checkedMultiList.length>0">{{checkedMultiList.join(', ')}}</span>
        </button>
        <div class="drop-show" *ngIf="showDropDown && listData.length>0">
            <div class="usersFilter__branchesField">
                <div class="usersFilter__branchWrappar">
                    <img class="usersFilter__searchIcon"
                        src="../../../../assets/Images/user-management/searchIcon.svg" />
                    <input (input)="handleFilter($event)" class="usersFilter__input" placeholder="Search" />
                </div>
            </div>
            <label *ngFor="let item of multiListData">
                <app-checkbox checkboxStatus="{{item.checked}}"
                    (checked)="getMultiSelectedValue($event.event.target.checked, item)"></app-checkbox>
                <span>{{item.name}}</span>
            </label>
        </div>
    </div>
</div>