import { Component, OnInit, Input } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCamera, faUsers } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/home/auth/auth.service';
import { RegExpPatterns } from 'src/app/share/enums/regex-pattern.enum';
import { UserSettingService } from 'src/app/share/services/User-Setting/user-setting.service';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-organisational-details',
  templateUrl: './organisational-details.component.html',
  styleUrls: ['./organisational-details.component.scss']
})
export class OrganisationalDetailsComponent implements OnInit {
  userCircleIcon             = faUserCircle;
  isPasswordHide             : boolean = true;
  isConfirmPasswordHide      : boolean = true;
  disableinedit              : boolean = true;
  editedprofile              : boolean = true;
  usesrProfileedit           : boolean = false;
  imageAvailable             : boolean = false;
  edit                       : boolean = false;
  isEditprofile              : boolean = false;
  organisationProfileForm   !: FormGroup;
  organisationDetailsData    : any;
  modalReference             : any;
  errorMessage               : any;
  errorMessage1             !: any;
  profileImage               : any;
  files                     !: any[];

  @ViewChild('fileInput') fileInput!: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private userSettingService: UserSettingService
  ) { }

  ngOnInit(): void {
    this.genrateProfileForm();
  }

  handleProfile() {
    this.usesrProfileedit = true

  }

  toggleEdit() {
    this.isEditprofile = true
    this.disableinedit = false
    this.edit = !this.edit;
    this.editedprofile = false
    this.profileImage = false
    if (this.edit) {
      this.setForm();
    }
  }

  setForm() {
    this.organisationProfileForm.setValue({
      addressone: this.organisationDetailsData.name,
      addresstwo: this.organisationDetailsData.addresstwo,
      addressthree: this.organisationDetailsData.addressthree,
      addressfour: this.organisationDetailsData.addressfour,
      addressfive: this.organisationDetailsData.addressfive,
    });
  }

  genrateProfileForm() {
    this.organisationProfileForm = this.formBuilder.group({
      addressone: ['', [Validators.required]],
      addresstwo: ['', [Validators.required]],
      addressthree: ['', [Validators.required]],
      addressfour: ['', [Validators.required]],
      addressfive: ['', [Validators.required]],
    });
  }

  upload(event: any) {
    if (event?.target?.files.length) {
      let files = event?.target?.files[0];
      let formData = new FormData();
      formData.append('file', files, files.name);
      this.userSettingService.uploadFile(formData).subscribe(
        (res: any) => {
          this.profileImage = res.data[0].objectUrl;
          this.organisationDetailsData.profileImage = this.profileImage;
          this.errorMessage = res.message
            ? res.message
            : `Profile image uploaded successfully`;
        },
        (error: any) => {
          this.errorMessage = `Failed to upload profile picture. Please try again`;
        }
      );
    }
  }

  cancel() {
    this.isEditprofile = false
    this.edit = false;
    this.editedprofile = true
    this.disableinedit = true

  }

  saveProfile() {
    this.isEditprofile = false
    this.disableinedit = true
    this.editedprofile = true
    if (this.organisationProfileForm.invalid) {
      return;
    }
  }

  protected readonly faUsers = faUsers;
  protected readonly faCamera = faCamera;
  
}
