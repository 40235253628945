import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RolesService } from 'src/app/share/services/roles-service/roles.service';
import { SdexAdminService } from 'src/app/share/services/sdex/sdex-admin.service';

@Component({
  selector: 'app-accept-reject-confirmation-modal',
  templateUrl: './accept-reject-confirmation-modal.component.html',
  styleUrls: ['./accept-reject-confirmation-modal.component.scss'],
})
export class AcceptRejectConfirmationModalComponent implements OnInit {
  @Input() acceptRejectData     !: 'Accept' | 'Reject';
  @Input() invitationId          : any;
  @Input() companyActivated      : any;
  @Input() companyActivatedData   :any;
  @Input() message               : string;
  @Input() roleId       : any;
  errorMsg              : any;
  isToast               : boolean


  constructor(
    public activeModal    : NgbActiveModal,
    private _service      : SdexAdminService,
    private roleService : RolesService,
  ) { }
  
  ngOnInit(): void { }

  // function to used Close modal
  closeDialog() {
    this.activeModal.close();
  }

  //check company accept and reject and IsActivateed
  confirmation(type: 'Accept' | 'Reject' | 'isActivated') {
    if (type === 'Accept') {
      this._service
        .acceptInvitation(this.invitationId)
        .subscribe((res: any) => {
          this.activeModal.close({
            status: true,
            message: 'company list Accepted',
          });
        });
    } else if (type === 'Reject') {
      this._service
        .rejectInvitation(this.invitationId)
        .subscribe((res: any) => {
          this.activeModal.close({
            status: true,
            message: 'company list rejected',
          });
        });
    } else {
      this.activeModal.close({
        status: true,
        message: 'company list Activated',
      });
    }
  }
  //delete Role
  deleteRole() {
    this.isToast=true
    this.roleService.deleteRole(this.roleId).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.activeModal.close({ status: true });
        }
      },
      error: (error: any) => {
        this.errorMsg = error?.error?.message;
      },
    });
  }
}
