import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { StorageKeys } from 'src/app/share/enums/storage.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http  : HttpClient,
    private storageService  : StorageService) { }

  login(payload: any): Observable<any> {
    let url = environment.API_URL + '/v1/oauth/token';
    return this.http.post(url, payload);
  }
  logout(): Observable<any> {
    let url = environment.API_URL + '/v1/oauth/logout';
    return this.http.get(url);
  }
  companySettings(payload: any,companyId:string): Observable<any> {
    let url = environment.API_URL + `/v1/company-preference/address/${companyId}`;
    return this.http.post(url, payload);
  }
  getcompanySettings(id: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-preference/${id}`
    return this.http.get(url)
  }

  getSelfDetails(): Observable<any> {
    let url = environment.API_URL + '/v1/user/self';
    return this.http.get(url);
  }

  // organisation details start

  getOrgDetails(id: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-preference/${id}`
    return this.http.get(url)
  }
  
  isAdmin() {
    let userData: any = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    if (userData && userData.enrollmentType == 'Admin') {
      return true;
    }
    return false;
  }

  isRootUser() {
    let userData: any = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    let isPrimary: boolean = false;
    if (
      userData &&
      userData.enrollmentType &&
      userData.enrollmentType == 'Admin'
    ) {
      return false;
    }
    userData.assignedTo.map((ele: any) => {
      
      isPrimary = ele.primary;
      
    });
    if (isPrimary) {
      return true;
    }
    return false;
  }

  getOrganizationStructureById(id: any) {
    let url = environment.API_URL + '/v1/company-structure';
    if (id) {
      url += '/' + id;
    }
    return this.http.get(url);
  }
}
