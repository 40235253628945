<div *ngIf="!isOrgSearch">
  <div class="usersFilter__branchesField">
    <div class="usersFilter__branchWrappar">
      <img class="usersFilter__searchIcon" src="../../../../assets/Images/user-management/searchIcon.svg" />
      <input #orgSearch [(ngModel)]="searchText" (input)="onChange(searchText)" class="usersFilter__input"
        placeholder="Search by zone/branch/area" />
      <div class="usersFilter__orgStructureWrapper">
        <img (click)="openOrganisationStructureOverlay()" class="usersFilter__orgStructure"
          src="../../../../assets/Images/user-management/orgStructure.svg" />
      </div>
    </div>
  </div>
  <div (click)="openSearchPopup()" id="searchpopup" class="createPackage__searchSuggestions"
    *ngIf="isSearchPopup && !isOrgSearch">
    <div class="createPackage__searchUserDetails" *ngFor="let value of data">
      <div class="createPackage__searchUserWrapper" (click)="selectOrganisation(value)">
        <div class="createPackage__searchUserNameLocation">
          <div class="createPackage__searchUserName-UserType" (click)="value.selected = !value.selected">
            {{ value.name}} <span *ngIf="value.selected"> <fa-icon [icon]="faCircleCheck"></fa-icon></span>
          </div>
          <div *ngIf="value.selected">
            <label style="display: flex;">
              <app-checkbox checkboxStatus="{{value.isAdmin}}"
                (checked)="selectAdminRole($event.event.target.checked, value)"></app-checkbox>
              <span class="createPackage__searchUserName-UserType">{{'Admin'}}</span>
            </label>
            <label *ngFor="let role of value.roles" style="display: flex;">
              <app-checkbox checkboxStatus="{{role.selected}}"
                (checked)="selectRole($event.event.target.checked, value, role)"></app-checkbox>
              <span class="createPackage__searchUserName-UserType">{{role.name}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isOrgSearch">
  <div class="usersFilter__branchesField">
    <div class="usersFilter__branchWrappar">
      <img class="usersFilter__searchIcon" src="../../../../assets/Images/user-management/searchIcon.svg" />
      <input #orgSearch [(ngModel)]="searchText" (input)="handleFilter(searchText)" class="usersFilter__input"
        placeholder="Search by zone/branch/area" />
    </div>
  </div>
  <div class="createPackage__searchSuggestions" *ngIf="isSearchPopup && isOrgSearch">
    <div class="createPackage__searchUserDetails" *ngFor="let value of packageOrgsData">
      <div class="createPackage__searchUserWrapper" (click)="selectOrgPackage(value)">
        <div class="createPackage__searchUserNameLocation">
          <div class="createPackage__searchUserName-UserType">
            {{ value.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>