import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss']
})
export class RadiobuttonComponent {
  @Input() radioText:string;
  @Input() radioClass:string;
  @Input() value: string;
  @Input() radioName: string;
  @Input() defaultCheck: Boolean;

  @Output() checked: EventEmitter<any> = new EventEmitter<void>();

  handleCheckboxChange(event: any) {
    this.checked.emit({
      event: event,
      name: this.value
    });
  }
}
