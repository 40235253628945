<table class="custom-table-list">
  <thead class="custom-table-list-headings">
    <th width="10%">Status</th>
    <th width="20%">Name</th>
    <th width="20%">Email ID</th>
    <th width="10%">Mobile No.</th>
    <!-- <th class="custom-col-width-20">Branches</th> -->
    <th width="20%">Roles</th>
    <th width="15%" colspan="3">Action</th>
  </thead>

  <tr class="custom-table-list-row-main cursor-pointer" *ngFor="let tableData of userData">
    <td width="10%" class="userTable__statusx clickable-td" (click)="editUser('status', tableData)">
      <div *ngIf="tableData.status === 'New'">
        <img src="../../../../assets/Images/user-management/user-invited.svg" alt="invited" />
      </div>
      <div *ngIf="tableData.status === 'Locked'">
        <img src="../../../../assets/Images/user-management/user-unverified.svg" alt="unverified" />
      </div>
      <div *ngIf="tableData.status === 'Active'">
        <img src="../../../../assets/Images/user-management/user-verified.svg" alt="verified" />
      </div>
      <div [ngClass]="{
        userTable__statusTextBlue: tableData.status === 'New',
        userTable__statusTextOrange: tableData.status === 'Locked',
        userTable__statusTextGreen: tableData.status === 'Active'
      }">
        {{ tableData.status }}
      </div>
      <p *ngIf="tableData.primary" class="badge badge-primary badge-lt-40">
        Primary
      </p>
    </td>
    <td width="20%" class="clickable-td" (click)="editUser('firstName', tableData)" [ngbTooltip]="tableData?.name"
      [placement]="'top-start'" [tooltipClass]="'custom-tooltip'">
      <span *ngIf="tableData?.firstName?.length < maxFilelength" class="documentRowCards__container--text">
        {{tableData?.firstName || tableData?.name}}
      </span>
      <span *ngIf="tableData?.firstName?.length >= maxFilelength" class="documentRowCards__container--text">
        {{largeFileName(tableData?.firstName || tableData?.name)}}...
      </span>
    </td>
    <td width="20%" class="clickable-td" (click)="editUser('email', tableData)"
      [ngbTooltip]="tableData?.emailAddress" [placement]="'top-start'" [tooltipClass]="'custom-tooltip'">
      <span *ngIf="tableData?.emailAddress?.length < maxFilelength" class="documentRowCards__container--text">
        {{tableData?.emailAddress}}
      </span>
      <span *ngIf="tableData?.emailAddress?.length >= maxFilelength" class="documentRowCards__container--text">
        {{largeFileName(tableData?.emailAddress)}}...
      </span>
    </td>
    <td width="10%" class="clickable-td" (click)="editUser('mobileNumber', tableData)">
      {{ tableData?.mobileNumber }}
    </td>
    <!-- <td class="custom-col-width-20 clickable-td no-white-space" (click)="editUser('company', tableData)">{{ tableData?.company }}</td> -->
    <td width="20%"class="clickable-td no-white-space" (click)="editUser('roles', tableData)">
      {{ tableData?.roles }}
    </td>
    <td width="5%"  *ngIf="isIbdic">
      <div class="d-flex btnright">
        <span data-bs-toggle="tooltip" ngbTooltip="Deactivate">
          <a tooltipClass="custom-tooltip" (click)="activateCompany(tableData,'Deactivate')"
            class="btn btn-primart btn-sm me-2" style="background-color: #de7141;border-color: #de7141;color: white;">
            <fa-icon [icon]="faLock"></fa-icon>
          </a>
        </span>
      </div>
    </td>
    <td width="5%"  *ngIf="isIbdic">
      <div class="d-flex btnright">
        <span data-bs-toggle="tooltip" ngbTooltip="Delete">
          <a tooltipClass="custom-tooltip" (click)="activateCompany(tableData,'Delete')"
            class="btn btn-primart btn-sm me-2" style="background-color: #de7141;border-color: #de7141;color: white;">
            <fa-icon [icon]="faTrash"></fa-icon>
          </a>
        </span>
      </div>
    </td>
    <td width="5%" class="custom-col-width-5" *ngIf="isIbdic">
      <div class="d-flex btnright">
        <span data-bs-toggle="tooltip" ngbTooltip="View">
          <a tooltipClass="custom-tooltip"
          (click)="editUser('', tableData)"
            class="btn btn-primart btn-sm me-2" style="background-color: #de7141;border-color: #de7141;color: white;">
           <fa-icon [icon]="faEye" style="color: white"></fa-icon>
          </a>
        </span>
      </div>
    </td>
    <td width="5%" class="custom-col-width-5 clickable-td">
      <p-overlayPanel #op>
        <ng-template pTemplate="content">

        </ng-template>
      </p-overlayPanel>
    </td>
  </tr>
</table>