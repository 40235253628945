import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { faCamera, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/home/auth/auth.service';
import { UserSettingService } from 'src/app/share/services/User-Setting/user-setting.service';

@Component({
  selector: 'app-update-company-settings',
  templateUrl: './update-company-settings.component.html',
  styleUrls: ['./update-company-settings.component.scss']
})
export class UpdateCompanySettingsComponent implements OnInit, OnChanges {

  @Input() address                  : any;
  @Input() addressInfo              : any;
  @Input() organisationDetailsData  : any;
  @Input() companyLogo              : any;
  @Input() isLRAddress              : boolean;
  @Output() closePanel              : EventEmitter<any> = new EventEmitter<any>();
  @Output() saveCompanyProfile      : EventEmitter<any> = new EventEmitter<any>();
  companySettingsFormGroup          : FormGroup;
  profileImage                      : any;
  errorMessage                      : any;

  @ViewChild('file') file           : ElementRef;

  constructor(
    private fb                  : FormBuilder,
    private userSettingService  : UserSettingService) { }

  ngOnInit(): void {
    if(!this.companySettingsFormGroup){
      this.createCompanySettingsForm();
    }
    if (this.isLRAddress) {
      this.companySettingsFormGroup.addControl('country', new FormControl(this.addressInfo?.country));
    }
  }

  ngOnChanges(): void {
    if(!this.companySettingsFormGroup){
      this.createCompanySettingsForm();
    }
    const  previousThis = this;
    if (this.addressInfo) {
      setTimeout(function () {
        previousThis.setCompayAddress(previousThis.addressInfo);
      })
     }
  }

  createCompanySettingsForm() {
    this.companySettingsFormGroup = this.fb.group({
      line1: [this.addressInfo?.line1],
      line2: [this.addressInfo?.line2],
      locality: [this.addressInfo?.locality],
      landmark: [this.addressInfo?.landmark],
      city: [this.addressInfo?.city],
      state: [this.addressInfo?.state],
      pincode: [this.addressInfo?.pincode],
    })
  }

  setCompayAddress(value: any) {
    this.companySettingsFormGroup.patchValue({
      line1: value?.line1,
      line2: value?.line2,
      locality: value?.locality,
      landmark: value?.landmark,
      city: value?.city,
      state: value?.state,
      pincode: value?.pincode,
    })
  }

  upload(event: any) {
    if (event?.target?.files.length) {
      let files = event?.target?.files[0];
      let formData = new FormData();
      formData.append('file', files, files.name);
      this.userSettingService.uploadFile(formData).subscribe(
        (res: any) => {
          this.profileImage = res.data[0].objectUrl;
          this.organisationDetailsData.profileImage = this.profileImage;
          this.errorMessage = res.message
            ? res.message
            : `Profile image uploaded successfully`;
        },
        (error: any) => {
          this.errorMessage = `Failed to upload profile picture. Please try again`;
        }
      );
    }
  }

  saveProfile() {
    var payload = {
      address: this.companySettingsFormGroup.value,
      profileImage: this.profileImage
    }
    this.saveCompanyProfile.emit(payload);
  }

  onCloseIconClick() {
    this.closePanel.emit(false);
    this.organisationDetailsData.profileImage = this.companyLogo;
    this.resetFileUploader()
    //this.setCompayAddress(this.addressInfo);
   }

   resetFileUploader() {
    this.file.nativeElement.value = null;
  }

  protected readonly faCamera = faCamera;
  protected readonly userCircleIcon = faUserCircle;

}
