export const RouteConstants = {
  Home: '',
  Login: 'login',
  AdminTemplatesList: 'admin-templates-list',
  CompanyTemplatesList: 'company-templates-list',
  LrTemplateGeneration: 'lr-generation',
  GeneretedLrList: 'generated-lrs',
  LrSettings: 'lr-settings',
  LrNumberSetting: 'lr-number-setting',
  SdexUsers: 'users',
  Notifications: 'notification',
  SubCompanyUsers: 'sub-company-users',
  ViewCompanyPreference: 'company-preference',
  ViewContactList: 'contact-list',
  SdexCompanyList: 'sdex/company',
  SdexbankList: 'sdex/bank',
  SdexreportList: 'sdex/reports-and-mis',
  CompanyList: 'company',
  CompanySettings: 'company-settings',
  SdexViewCompanyDetails: 'sdex-view-company',
  ViewCompanyDetails: 'view-company',
  DocumentValut: 'document-vault',
  Package: 'packages',
  IncommingPackages: 'incoming',
  OutgoingPackages: 'outgoing',
  DraftPackages: 'draft',
  PackageDetails: 'package-details',
  CreatePackage: 'create-new-package',
  PackageApproval: 'package-approval',
  UserSetting: 'user-setting',
  Roles: 'roles',
  NotifyUsers: 'notify-users',
  Dashboard: 'dashboard',
  OrganizationStructure: 'organization-structure',
  EwayBill: 'eway-bill/credentials',
  CreateCredentials: 'createcredentials',
  Iba: 'iba',
  Einvoice: 'einvoice',
  ReportsMis: 'reports-and-mis',
  RecieptsPayments: 'reciepts-and-payments',
  Transactions: 'transactions',
  Corporates: 'corporates',
  CorporatesDestails: 'corporate-details',
  CorporateGroups: 'corporate-groups',
  BanksComponent: 'sdex/banks',
  IbdicNotifications: 'notifications',
  EBRDetails: 'ebrDetails'
};
