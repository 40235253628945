import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AcceptRejectConfirmationModalComponent } from '../../../admin/company/accept-reject-confirmation-modal/accept-reject-confirmation-modal.component';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
})
export class UserTableComponent {

  @Input() userData     : any;
  @Output() userEvent   = new EventEmitter<any>();
  maxFilelength         : number = 25;
  isIbdic               : boolean = true; 

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userData'].currentValue) {
      this.getRolesAndBranches();
    }
  }

  constructor(private modalService: NgbModal) { }

  getRolesAndBranches() {
    for (let i = 0; i < (this.userData || []).length; i++) {
      this.userData[i].branches = [];
      this.userData[i].branchRoles = [];
      this.userData[i].assignedTo.map((item: any) => {
        if (item.company?.name) {
          this.userData[i].branches.push(item.company?.name);
        }
        if (item.isAdmin) {
          this.userData[i].branchRoles.push('Admin');
        } else {
          item?.roles?.map((y: any) => {
            if (y?.name) {
              this.userData[i].branchRoles.push(y.name);
            }
          });
        }
      });
      this.userData[i].company = this.userData[i].branches.join(', ');
      this.userData[i].roles = this.userData[i].branchRoles.join(', ');
    }
  }

  editUser(value: any, user: any) {
    this.userEvent.emit({ event: value, user });
  }

  /*added fa icons */
  protected readonly faEye = faEye;
  protected readonly faBan = faBan;
  protected readonly faLock = faLock;

  /* to activate company */
  activateCompany(userData: any, isActive: any) {
    let dialogRef = this.modalService.open(
      AcceptRejectConfirmationModalComponent,
      {
        backdrop: 'static',
        size: 'md',
        keyboard: false,
        centered: true,
      }
    );
    dialogRef.componentInstance.companyActivated = isActive;
    dialogRef.componentInstance.message = `Do You Want To ${isActive} "${userData?.firstName}" ? `
    dialogRef.result.then(
      (result) => {
        if (result?.status) {
          if (isActive == 'Activate') {
            this.userData
              .activateCompany(userData._id, 'activate')
            if (userData.status == 'success') {
            }
            ;
          } else if (isActive === 'Deactivate') {
            this.userData.deleteRootCompany(userData._id).subscribe((res: any) => {

            });
          }
        }
      }
    );
  }

  largeFileName(fileName: string) {
    return fileName.substring(0, this.maxFilelength);
  }
  protected readonly faTrash = faTrash;
}
