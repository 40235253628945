<div class="createPackage__header">
    <div class="createPackage__heading">{{createPackageHeader || 'Create Package'}}</div>
    <div class="createPackage__saveDraft cursor-pointer" (click)="sendToDrafts()" *ngIf="(draftStatus !== 'Draft') && (draftStatus !== 'Rejected')">
        <div class="createPackage__saveDraftIcon"><img src="../../../../assets/Images/create-package/draftSaved.svg" alt="draftSaved"></div>
        <div class="createPackage__saveDraftText">Save As Draft</div>
    </div>
  <div class="createPackage__saveDraft cursor-pointer" (click)="sendToDrafts()" *ngIf="draftStatus === 'Draft'">
    <div class="createPackage__saveDraftIcon"><img src="../../../../assets/Images/create-package/draftSaved.svg" alt="draftSaved"></div>
    <div class="createPackage__saveDraftText">Update Draft</div>
  </div>

</div>
