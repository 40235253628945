import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouteConstants } from 'src/app/share/constants/routes.constants';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
  constructor(private router: Router, public activeModal: NgbActiveModal) {
    
  }

  logout() {
    localStorage.clear();
    this.router.navigate([RouteConstants.Login]);
    this.activeModal.close({ status: true });
  }
  closeDialog() {
    this.activeModal.close();
  }
}
