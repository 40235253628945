import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { AuthService } from '../auth/auth.service';
import { StorageKeys } from '../../share/enums/storage.enum';
import { environment } from '../../../environments/environment';
// @ts-ignore
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.js';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { LogoutComponent } from '../logout/logout.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserSettingService } from 'src/app/share/services/User-Setting/user-setting.service';
import {faSignOut} from "@fortawesome/free-solid-svg-icons/faSignOut";
import {Router, provideRouter} from "@angular/router";
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { RouteConstants } from 'src/app/share/constants/routes.constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  enrollmentType: any;
   userCircleIcon = faUserCircle;
  constructor(
    private storageService: StorageService,
    public authService: AuthService,
    private userSettingService: UserSettingService,
    private router: Router, 

  ) {}
  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSubMenuActive: boolean = false;
  selectedMenuItem: number = 1;
  user: any = {};
  nextLevel: any;
  userName: string | undefined;
  userDetailsData: any;
  profilePic: any;
  name:string
  selectedCompany: any;
  defaultProfilePic = `${environment.IMAGE_URL}blank-profile-picture.png`;
  bootstrap: any;
  isShowProfile: boolean = false;
  rolesDisabled = false;
  showTooltip: boolean = false;
  ibdic: boolean = true;
  isMaker: boolean = false;
  isChecker: boolean = false;
   
   
   
   ngOnInit() {
    this.ibdic = environment.ibdic;
    this.rolesDisabled = environment.rolesDisabled;
    this.getSelfDetails();
    Array.from(
      document.querySelectorAll('button[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));

    this.userSettingService.getProfilePic().subscribe((res : any)=>{
      this.profilePic=res;
    })
    this.userSettingService.getUpdatedUserName().subscribe((res : any)=>{
      this.userDetailsData.name = res;
    })
   
  }

  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);

  toggleSubMenu() {
    this.isSubMenuActive = !this.isSubMenuActive;
  }

  isMenuItemActive(index: number): boolean {
    return this.selectedMenuItem === index;
  }

  getSelfDetails() {
    this.authService.getSelfDetails().subscribe((res) => {
       this.storageService.setDataToLocalStorage(
        StorageKeys.SELF_DETAILS,
        res.data
      );
      this.userDetailsData = res.data;

      this.profilePic = this.userDetailsData?.profileImage;
    });
    this.user = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    this.enrollmentType = this.user.enrollmentType;
    this.selectedCompany = this.user.company;

    let mEmail = this.userDetailsData?.emailAddress;
    if(mEmail == 'ibdic.maker@ibdic.com'){
      this.isMaker = true;
      this.isChecker = false;
    } else if(mEmail == 'ibdic.checker@ibdic.com'){
      this.isMaker = false;
      this.isChecker = true;
    }
  }

    logout() {
    this.authService.logout().subscribe(res =>{
      if(res.data){
        localStorage.clear();
        this.router.navigate([RouteConstants.Login]);
      }
    })
   }

   getOrganisationStructureById(companyId: string, assignedTo: any) {
    this.authService
      .getOrganizationStructureById(undefined)
      .subscribe((res: any) => {
        if (
          res &&
          res.data &&
          res.data.structure &&
          res.data.structure.length
        ) {
          if (res.data.rootCompany === companyId) {
            this.nextLevel = res.data.structure[0];
          } else if (
            assignedTo &&
            assignedTo[0] &&
            assignedTo[0].company &&
            assignedTo[0].company.structure
          ) {
            let level = assignedTo[0].company.structure.level;
            if (res.data.structure[level]) {
              this.nextLevel = res.data.structure[level];
            }
          }
        }
      });
  }
  hideText: boolean = false;
  buttonText: string = '<';

  toggleTextVisibility() {
    this.hideText = !this.hideText;
    this.buttonText = this.hideText ? '>' : '<';
    this.showTooltip=!this.showTooltip;
  }

  hideListText(){
    this.hideText =true
    this.buttonText = '>' 
    this.showTooltip=true
  }
  onCompanyChange(event: any) {
    if (event?.target?.value) {
      this.user = this.storageService.getDataFromLocalStorage(
        StorageKeys.SELF_DETAILS
      );
      this.selectedCompany = event.target.value;
      this.user.company = this.selectedCompany;
      this.storageService.setDataToLocalStorage(
        StorageKeys.PRESENT_COMPANY,
        this.selectedCompany
      );
      this.storageService.setDataToLocalStorage(
        StorageKeys.SELF_DETAILS,
        this.user
      );
      location.reload();
    }
  }

  get getenrollmentType() {
    if (this.userDetailsData) {
      return this.userDetailsData.assignedTo[0].company.enrollmentType;
    }
  }

  protected readonly faEllipsisVertical = faEllipsisVertical;
  protected readonly faSignOut = faSignOut;
  protected readonly provideRouter = provideRouter;
}
