<div class="admin-steps-card__main m-3" >
  <div class="admin-steps-card__top">
    <div class="admin-steps-image">
      <img [src]="cardData.imageSrc" [alt]="cardData.title"/>
    </div>
    <div *ngIf="cardData?.title" class="admin-steps__title">{{ cardData?.title }}</div>
    <div *ngIf="cardData?.description" class="admin-steps__description description-onboarding">
      {{ cardData?.description }}
    </div>
  </div>
  <div *ngIf="cardData?.title" class="admin-steps-card__middleLine"></div>
  <div class="admin-steps-card__bottom">
    <div *ngIf="cardData?.countValue"  [ngClass]="{
        'admin-card__countValue': cardData.countValue != 'View Added Network',
        'admin-card__countValueUpdated':
          cardData.countValue === 'View Added Network'
      }">
      {{ cardData.countValue }}
    </div>
    <app-common-button [buttonClass]="
        cardData.buttonLabel === 'Verified' ||
        cardData.buttonLabel === 'Generated'
          ? 'button_sucess'
          : cardData.buttonType
      " [disabled]="isDisabled" [buttonLabel]="cardData.buttonLabel"
      (clickEvent)="onClickEvent(cardData.buttonLabel)"></app-common-button>
  </div>
</div>