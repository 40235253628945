import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserSettingService {
  constructor(private http: HttpClient) {}

  searchUserInSystem(searchText: any): Observable<any> {
    let url = environment.API_URL + '/v1/user/users-in-system';
    return this.http.post(url, searchText);
  }
  updateProfile(payload: any): Observable<any> {
    let url = environment.API_URL + '/v1/user/self';
    return this.http.put(url, payload);
  }

  uploadFile(payload: any): Observable<any> {
    let url = environment.API_URL + '/v1/digilocker/public-file/upload';
    return this.http.post(url, payload);
  }

  private isProfilePicUpdated: Subject<any> = new Subject();
  private updatedUserName : Subject<String> = new Subject();

  public getUpdatedUserName() : Observable<String> {
    return this.updatedUserName.asObservable();
  }

  public setUpdatedUserName(name : String) {
    this.updatedUserName.next(name);
  }

  public getProfilePic(): Observable<any> {
    return this.isProfilePicUpdated.asObservable();
  }

  public setProfilePic(uploadedProfilePic: any) {
    this.isProfilePicUpdated.next(uploadedProfilePic);
  }

  updateCompanydetails(payload: any, companyId: string): Observable<any> {
    let url =
      environment.API_URL + '/v1/company/' + companyId + '/update-details';
    return this.http.post(url, payload);
  }

  userSubCompanyDisable(userId: any): Observable<any> {
    let url = environment.API_URL + '/v1/user/sub-company-user/'+userId;
    return this.http.delete(url);
  }

  userDisable(userId: any): Observable<any> {
    let url = environment.API_URL + '/v1/user/delete/'+userId;
    return this.http.delete(url);
  }
   getblockchainkey(userId:any):Observable<any>{
    let url=environment.API_URL +'/v1/blockchain-key/retrieve/'+userId;
    return this.http.get(url);
   }
}
