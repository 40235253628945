import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LogoutComponent } from './logout/logout.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SetupAccountComponent } from './setup-account/setup-account.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedAccessModule } from '../shared-access/shared-access.module';
import { SharedModule } from '../share/shared.module';
import { LogoComponent } from './logo/logo.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    HomeComponent,
    LoginComponent,
    SidebarComponent,
    LogoutComponent,
    ForgotPasswordComponent,
    SetupAccountComponent,
    LogoComponent,
  ],
  exports: [SidebarComponent],
  imports: [
    NgbModule,
    CommonModule,
    HomeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbDropdownModule,
    SharedAccessModule,
    SharedModule
  ],
})
export class HomeModule {}
