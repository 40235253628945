<div class="offCanvas__container">
    <div class="offCanvas__contentWrapper">
        <div class="offCanvas__header">
            <div class="offCanvas__title">Add User</div>
            <img (click)="onCloseIconClick()" class="offCanvas__crossIcon" src="../../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon">
        </div>
        <div class="offcanvas__content">
            <div class="offcanvas__orgDetails">
                <div class="offCanvas__orgName-inputField">
                    <app-input-filed  label="Name" placeholder="Input user’s name" value="Input user’s name"></app-input-filed>
                </div>
                <div class="offCanvas__orgName-inputField">
                    <app-input-filed  label="Email ID" placeholder="Input user’s email ID" value="Input user’s email ID"></app-input-filed>
                </div>
                <div class="offCanvas__phoneNumber-inputFiled">
                    <app-input-filed  label="Phone Number" placeholder="Input user’s phone number" value="Input user’s phone number"></app-input-filed>
                </div>
                <div class="users-filter__dropdownText">Select Branches</div>
                <div  class="users-filter__branchesField">
                    <div class="users-filter__branchWrappar">
                        <img class="users-filter__searchIcon" src="../../../../assets/Images/user-management/searchIcon.svg">
                        <input class="users-filter__input" placeholder="Search here">
                        <div class="users-filter__orgStructureWrapper">
                        <img class="users-filter__orgStructure" src="../../../../assets/Images/user-management/orgStructure.svg">
                        </div>
                    </div> 
                </div>
            </div>
            <div class="offcanvas__orgAddress">
                <div class="addUser__dropdown">
                    <div class="addUser__dropdownLabel">Select roles this user will have access to</div>
                    <app-dropdown [options]="roleOptions"></app-dropdown>
                </div>
            </div>
            <div class="addUser__dropdownSelectedContent">
                <div class="addUser__dropdownSelectedContentText">Manager</div>
                <img class="addUser__delete" src="../../../../assets/Images/user-management/delete.svg">
            </div>
        </div>
    </div>
    
    <div class="offCanvas__footer">
        <app-common-button buttonClass="smallbutton" buttonLabel="Cancel"></app-common-button>
        <div >
            <app-common-button buttonClass="smallFilledButton" buttonLabel="Save"></app-common-button>
        </div>
    </div>
</div>