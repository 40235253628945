import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})

export class ConfirmationPopupComponent {
  @Input() message        : any;
  @Input() header         : any;
  @Output() newFolderName : EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal) {}

  ngOnInit() {
  }
}
