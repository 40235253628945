<div class="offCanvas__container">
  <form [formGroup]="companySettingsFormGroup" (ngSubmit)="saveProfile()">
    <div class="offCanvas__contentWrapper">
      <div class="offCanvas__headerWrapper">
        <div class="offCanvas__header">
          <div class="offCanvas__title">Edit Address</div>
          <img
            (click)="onCloseIconClick()"
            class="offCanvas__crossIcon"
            src="../../../../assets/Images/organisation-structure/Cross.svg"
            alt="cross icon"
          />
        </div>
      </div>
      <div class="offcanvas__content">
        <div class="offcanvas__orgDetails">
          <div
            *ngIf="!isLRAddress"
            class="organisationalDetails__imageContainer"
          >
            <div class="profile-image" (click)="file.click()">
              <ng-container *ngIf="organisationDetailsData?.profileImage">
                <img
                  src="{{ organisationDetailsData?.profileImage }}"
                  alt="Image"
                />
                <span><fa-icon [icon]="faCamera"></fa-icon></span>
              </ng-container>
              <ng-container *ngIf="!organisationDetailsData?.profileImage">
                <fa-icon
                  [icon]="userCircleIcon"
                  style="color: gray; font-size: 100px"
                >
                </fa-icon>
                <span><fa-icon [icon]="faCamera"></fa-icon></span>
              </ng-container>
            </div>
            <div class="profile-change">
              <input
                style="display: none"
                #file
                type="file"
                accept="image/*,jpg,png"
                (change)="upload($event)"
              />
            </div>
          </div>

          <app-input-filed
            label="Address Line 1"
            [control]="$any(companySettingsFormGroup).controls['line1']"
            placeholder="Address Line 1"
            [value]="address?.line1"
          ></app-input-filed>

          <app-input-filed
            label="Address Line 2"
            [control]="$any(companySettingsFormGroup).controls['line2']"
            placeholder="Address Line 2"
            [value]="address?.line2"
          ></app-input-filed>

          <app-input-filed
            label="Locality"
            [control]="$any(companySettingsFormGroup).controls['locality']"
            placeholder="Locality"
            [value]="address?.locality"
          ></app-input-filed>

          <app-input-filed
            label="Landmark"
            [control]="$any(companySettingsFormGroup).controls['landmark']"
            placeholder="Landmark"
            [value]="address?.landmark"
          ></app-input-filed>

          <app-input-filed
            label="City"
            [control]="$any(companySettingsFormGroup).controls['city']"
            placeholder="City"
            [value]="address?.city"
          ></app-input-filed>

          <app-input-filed
            label="State"
            [control]="$any(companySettingsFormGroup).controls['state']"
            placeholder="State"
            [value]="address?.state"
          ></app-input-filed>

          <app-input-filed
            *ngIf="isLRAddress"
            label="Country"
            [control]="$any(companySettingsFormGroup).controls['country']"
            placeholder="Country"
            [value]="address?.country"
          ></app-input-filed>

          <app-input-filed
            label="Pincode"
            [control]="$any(companySettingsFormGroup).controls['pincode']"
            placeholder="Pincode"
            [value]="address?.pincode"
          ></app-input-filed>
        </div>
      </div>
    </div>

    <div class="offCanvas__footer">
      <app-common-button
        type="button"
        (click)="onCloseIconClick()"
        buttonClass="smallbutton"
        buttonLabel="Cancel"
      ></app-common-button>
      <div>
        <app-common-button
          type="submit"
          buttonClass="smallFilledButton"
          buttonLabel="Save"
        ></app-common-button>
      </div>
    </div>
   </form>
</div>
