import { Component, Input } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})

export class TextAreaComponent {
  @Input() label         : string;
  @Input() placeholder   : string;
  @Input() value         : string;
  @Input() rows          : string;
  @Input() cols          : string;
  @Input() type          : string;
  @Input() className     : string;
  @Input() starIcon      : any;
  @Input() control      !: FormControl;
  @Input() ngModel       : NgModel;

  constructor() { }

  ngOnInit(): void { }
}
