<div class="custom-dropdown">
  <div class="dropdown-label" (click)="toggleDropdown()">
    <div class="dropdownText">{{ selectedOption }}</div>
    <img src="../../../assets/Images/user-management/dropdown__arrow.svg">
  </div>
  <div class="dropdown-menu" *ngIf="isDropdownOpen" [class.show]="isDropdownOpen">
    <div class="dropdown-option" *ngFor="let option of options">
      <input type="checkbox" [id]="option" [checked]="selectedOptions.includes(option)"
        (change)="toggleOption(option)" />
      <label [for]="option">{{ option.name }}</label>
    </div>
  </div>
</div>
