import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  setDataToLocalStorage(name: string, value: any) {
    //Return if name or value not exists, as saving null is not required
    if (!name || !value) {
      return;
    }
    try {
      //If sent value is JSON, stringify and save the string
      if (value && typeof value === 'string') {
        localStorage.setItem(name, this.encrypt(value));
      } else {
        let presentCompany = localStorage.getItem('presentCompany');
        //let presentRoles = localStorage.getItem('presentRoles');
        if (name === 'selfDetails' && presentCompany) {
          value.company = presentCompany;
        } else if (
          name === 'selfDetails' &&
          !value.company &&
          (value.assignedTo || []).length
        ) {
          value.company = value.assignedTo[0].company._id;
        }

        if (name === 'selfDetails' && value.company) {
          for (let i = 0; i < (value.assignedTo || []).length; i++) {
            if (value.assignedTo[i].company._id === value.company) {
              value.presentRoles = value.assignedTo[i].roles || [];
              value.primary = value.assignedTo[i].primary;
              value.isAdmin = value.assignedTo[i].isAdmin;
            }
          }
        }
        localStorage.setItem(name, this.encrypt(JSON.stringify(value)));
      }
    } catch (err) {
      console.info('May be issue with local storage');
    }
  }

  getDataFromLocalStorage(item: any): any {
    //We can return if item is not of type string
    if (!item || typeof item !== 'string') {
      return;
    }
    const localData =  localStorage.getItem(String(item));
    if (!localData) {
      return;
    }
    try {
      //If it is valid JSON, parse and send

      return JSON.parse(this.decrypt(localData));
    } catch (e) {
      return this.decrypt(localData);
    }
  }

  removeDataFromLocalStorage(item: any) {
    //We can return if item is not of type string
    if (!item || typeof item !== 'string') {
      return;
    }
    localStorage.removeItem(String(item));
  }

  clearLocalStorage() {
    //Clear entire storage, created by the domain
    localStorage.clear();
  }

  encrypt(value: any) {
    return CryptoJS.AES.encrypt(value, environment.PRIVATE_KEY).toString();
  }

  decrypt(value: any) {
    try{
      return CryptoJS.AES.decrypt(value, environment.PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
    }catch (ex){
      return '';
    }
  }
}
