import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  @Input() checkBoxKey      : string;
  @Input() checkBoxName     : string;
  @Input() checkboxStatus   : any;
  @Input() checkBoxClass    : string;
  @Input() className        : string;
  @Input() boxClass         : string;
  @Input() editClass        : string;
  @Input() editCheckBox     : String;
  @Output() checked         : EventEmitter<any> = new EventEmitter<void>();

  handleCheckboxChange(event: any) {
    this.checked.emit({
      event: event,
      name: this.checkBoxKey || this.checkBoxName
    });
  }
  
}
