<section class="infoCards__container">
    <div class="infoCards__title">
        {{verified.name}}
    </div>
    <div class="infoCards__Details">
        <div class="infoCards__Details--label">
            {{verified.id_label}}
        </div>
        <div class="infoCards__Details--data">
            {{verified.id_value}}
        </div>
    </div>
    <div class="infoCards__Details">
        <div class="infoCards__Details--label">
            {{verified.date_label}}
        </div>
        <div class="infoCards__Details--data">
            {{verified.date_value}}
        </div>
    </div>

    <div class="infoCards__auth">
        <div class="infoCards__authIcon">
            <img [src]="verified.icon" alt="">
        </div>
        <div [ngClass]="{'infoCards__authText--orange':verified.status}" class="infoCards__authText">
            {{verified.verification}}
        </div>
    </div>
    <div class="infoCards__Verifybtn">
        <app-common-button *ngIf="verified.status" buttonClass="smallFilledButton"
            buttonLabel="Verify Now"></app-common-button>
    </div>

</section>