import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-common-articles-card',
  templateUrl: './common-articles-card.component.html',
  styleUrls: ['./common-articles-card.component.scss']
})

export class CommonArticlesCardComponent {

  @Input() articleCardData  : any;
  @Input() ibdic            : boolean;

}