import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-common-button',
  templateUrl: './common-button.component.html',
  styleUrls: ['./common-button.component.scss']
})

export class CommonButtonComponent {

  @Input() buttonClass      : string;
  @Input() buttonLabel      : string;
  @Input() type             : string;
  @Input() disabled         : boolean;
  @Input() loadingIcon      : boolean = false;
  @Input() onClick          : any;
  @Input() icon             : any
  @Output() clickEvent      : EventEmitter<any> = new EventEmitter<void>();
  
  protected readonly faSpinner = faSpinner;

  onClickEvent(event: any) {
    if (!this.loadingIcon && !this.disabled) {
      this.clickEvent.emit();
    }
  }

}
