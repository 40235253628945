<div class="side-nav-bar__body">
    <div class="side-nav-bar__bodySection">
        <div class="side-nav-bar__bodyWrapper">
            <div class="side-nav-bar__logoWrapper" [ngClass]="{'side-nav-bar__logoBody': hideText}">
                <div class="side-nav-bar__logo">
                    <div class="side-nav-bar__logoSection">
                        <img src="../../../../assets/Images/side-nav-bar/sdx__logo.svg">
                        <img *ngIf="hideText" class="side-nav-bar__icon"
                            src="../../../../assets/Images/side-nav-bar/sdex__logo.svg">
                    </div>
                </div>
            </div>
            <div class="side-nav-bar__content" [ngClass]="{'side-nav-bar__contentWrapper': hideText}">
                <div class="side-nav-bar__contents">
                    <div class="side-nav-bar__dashboard">

                        <img class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Dashboard.svg">
                        <div *ngIf="hideText" class="side-nav-bar__dashboardText">Dashboard</div>
                        <div class="side-nav-bar__active" [ngClass]="{'side-nav-bar__activeState': hideText}"></div>
                    </div>

                    <div class="side-nav-bar__dashboard">
                        <img class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Packages.svg">
                        <div *ngIf="hideText" class="side-nav-bar__contentText">Packages</div>
                    </div>
                    <div class="side-nav-bar__dashboard">
                        <img class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/DocumentVoult.svg">
                        <div *ngIf="hideText" class="side-nav-bar__contentText">Document Vault</div>
                    </div>
                    <div class="side-nav-bar__network">
                        <img class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Network.svg">
                        <div *ngIf="hideText" class="side-nav-bar__contentText">Network</div>
                    </div>
                </div>
                <div class="side-nav-bar__options" [ngClass]="{'side-nav-bar__optionsWrapper': hideText}">
                    <div class="side-nav-bar__dashboard">
                        <img class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Users.svg">
                        <div *ngIf="hideText" class="side-nav-bar__contentText">Users</div>
                    </div>
                    <div class="side-nav-bar__dashboard">
                        <img class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/Roles.svg">
                        <div *ngIf="hideText" class="side-nav-bar__contentText">Roles</div>
                    </div>
                    <div class="side-nav-bar__dashboard">
                        <img class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/OrgStructure.svg">
                        <div *ngIf="hideText" class="side-nav-bar__contentText">Org. Structure</div>
                    </div>
                    <div class="side-nav-bar__settings">
                        <img class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/settingsImage.svg">
                        <div *ngIf="hideText" class="side-nav-bar__contentText">Global Settings</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="side-nav-bar__notificationSection"
            [ngClass]="{'side-nav-bar__notificationSectionWrapper': hideText}">
            <div class="side-nav-bar__notificationWrapper">
                <img class="side-nav-bar__images" src="../../../assets/Images/side-nav-bar/notification.svg">
                <div *ngIf="hideText" class="side-nav-bar__contentText">Notification</div>
            </div>
            <div [ngClass]="{'side-nav-bar__userWrapper': !hideText, 'side-nav-bar__userSection': hideText}"
                (click)="toggleTextVisibility()" class="side-nav-bar__userWrapper">
                <div [ngClass]="{'side-nav-bar__user': !hideText, 'side-nav-bar__admin': hideText}"
                    (click)="toggleTextVisibility()" class="side-nav-bar__user">
                    <img class='side-nav-bar__userImage' src="../../../../assets/Images/side-nav-bar/user.svg">
                    <div *ngIf="hideText" class="side-nav-bar__userText">Ravi Varma</div>
                </div>
            </div>
            <div class="side-nav-bar__button" [ngClass]="{ 'side-nav-bar__toggleButton': hideText }"
                (click)="toggleTextVisibility()">
                <img *ngIf="!hideText" src="../../../assets/Images/side-nav-bar/closeArrow.svg" alt="Toggle Button" />
                <img *ngIf="hideText" src="../../../assets/Images/side-nav-bar/openArrow.svg" alt="open arrow" />
                <div *ngIf="hideText">{{ buttonText }}</div>
            </div>
        </div>
    </div>
</div>