import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-input-filed',
  templateUrl: './input-filed.component.html',
  styleUrls: ['./input-filed.component.scss'],
})

export class InputFiledComponent implements OnInit {
  @Input() label         : string;
  @Input() labelClass    : string;
  @Input() placeholder   : string;
  @Input() value         : string;
  @Input() type          : string;
  @Input() class         : string;
  @Input() minlength     : string;
  @Input() maxDate       : String;
  @Input() maxlength     : number | string;
  @Input() maxDigits     : number;
  @Input() max           : number;
  @Input() pattern       : any;
  @Input() starIcon      : any;
  @Input() control       : FormControl;
  @Input() toUpperCase   : boolean;
  @Input() readonly      : boolean;

  onChange(event: any) {
    const digits = this.control?.value?.replace(/\D/g, '');
    if (digits?.length > this.maxDigits) {
      this.control.setValue(digits.slice(0, this.maxDigits));
    }
  }

  ngOnInit(): void {
    this.control?.valueChanges.pipe(debounceTime(500))
      .pipe(distinctUntilChanged()).subscribe((res: any) => {
        if (this.control.value) {
          const trimmedValue = this.control.value;
          const processedValue = this.toUpperCase ? trimmedValue.replace(/\s+/g, ' ').toUpperCase() : trimmedValue.replace(/\s+/g, ' ');
          const finalValue = processedValue.replace(/^\s+/, '');
          this.control.setValue(finalValue, { emitEvent: false });
        }
      })
  }

}
