
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements AfterViewInit {
  @Input()  options             : any[] = [];
  @Output() SendSelectedOptions : EventEmitter<any> = new EventEmitter<any>
  selectedOptions               : any[] = [];
  isDropdownOpen                = false;
  selectedOption                = 'Select Role';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }


  toggleOption(option: any) {
    const index = this.selectedOptions.findIndex((item: any) => item.value === option.value);
    if (index > -1) {
      this.selectedOptions.splice(index, 1);
    } else {
      this.selectedOptions.push(option);
    }
    this.SendSelectedOptions.emit(this.selectedOptions);
    this.selectedOption = this.selectedOptions.length > 0 ? `${this.selectedOptions.length} selected` : 'Select Role';
  }

  @HostListener('document:click', ['$event.target'])
  onDocumentClick(target: HTMLElement) {
    // Check if the clicked element is inside the dropdown or the dropdown label
    const dropdownElement = this.elementRef.nativeElement.querySelector('.custom-dropdown');
    if (!dropdownElement.contains(target)) {
      this.isDropdownOpen = false;
    }
  }

}
