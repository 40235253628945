<div class="createAccount__bodySection">
<div class="createAccount__body">
  <div class="createAccount__section">
    <div class="accountLogin__logo" *ngIf="!ibdic">
      <img src="../../../assets/Images/login/headerLogo.svg">
      <img src="../../../assets/Images/login/sdexLogo.svg">
    </div>
    <div class="accountLogin__logo" *ngIf="ibdic">
      <img class="ibdic-logo" src="../../../assets/ibdic/images/login/IBDIC1.png" [ngStyle]="showForm5?{'margin-top': '38px'} : {}">
      <img class="ibdic-name" src="../../../assets/ibdic/images/login/ibdic2.png" [ngStyle]="showForm5?{'margin-top': '27px'} : {}">
    </div>
    <form *ngIf="showForm4" (ngSubmit)="verifySecondaryUser()" name="registerForm" [formGroup]="secondaryForm">
      <div class="createAccount__signIn">
        <div class="createAccount__setup">Setup your Account</div>
        <div *ngIf="ibdic" class="createAccount__inputWrapper1 pt-4">
          <div class="createAccount__inputSection">
            <div class="createAccount__inputLabel">
              Enter mobile number
            </div>
            <app-input-filed [maxlength]="10" placeholder="Mobile Number" [control]="$any(secondaryForm).controls['mobileNumber']">
            </app-input-filed>
            <!-- <div *ngIf="
                secondaryForm.get('emailAddress')?.hasError('pattern') &&
                !secondaryForm.get('emailAddress')?.hasError('required')
              ">
              <small class="text-danger">Please enter a valid email address</small>
            </div> -->
          </div>
        </div>
        <div class="createAccount__inputWrapper1 pt-4">
          <div class="createAccount__inputSection">
            <div class="createAccount__inputLabel">
              Enter a registered email
            </div>
            <app-input-filed placeholder="Email" [control]="$any(secondaryForm).controls['emailAddress']"
              id="emailAddress" required>
            </app-input-filed>
            <div *ngIf="
                secondaryForm.get('emailAddress')?.hasError('pattern') &&
                !secondaryForm.get('emailAddress')?.hasError('required')
              ">
              <small class="text-danger">Please enter a valid email address</small>
            </div>
          </div>
          <div class="mt-2 text-center text-danger">
            {{ errorMsg }}
          </div>
        </div>
        <div class="createAccount__error">
          <img src="../../../assets/Images/login/error.svg" />
          <div class="createAccount__errorText">
            You will receive a mail with an OTP to verify your account
          </div>
        </div>
      </div>
      <div class="createAccount__button">
        <button class="createAccount__signInButton" type="submit" [disabled]="secondaryForm.invalid">
          {{ibdic?'Continue':' Verify Email'}}
        </button>
      </div>
      <div class="accountLogin__signUp">
        <div class="accountLogin__signupText">Already have an account?</div>
        <a style="text-decoration: none" [routerLink]="['/home']">
          <div class="accountLogin__passwordText">Sign In</div>
        </a>
      </div>
    </form>

    <form *ngIf="showForm5" #form2="ngForm" name="registerForm2" [formGroup]="registerForm5" class="p-3" [ngStyle]="ibdic?{'margin-top': '-58px'} : {}">
      <div class="createAccount__section">
        <div class="createAccount__signIn">
          <div class="createAccount__setup">Setup Password</div>
          <div class="createAccount__addCredintials">
            Verify OTP and create a password
          </div>
          <div class="createAccount__inputWrapper">
            <div *ngIf="!ibdic" class="createAccount__inputSection">
              <div class="createAccount__inputLabel">Enter an OTP</div>
              <div class="createAccount__inputWrapper">
                <input class="createAccount__input" placeholder="******" formControlName="otp" id="password"
                  type="number" required />
                <button [ngClass]="disabled ?'disableBtn':'createAccount__verifyButton'" [disabled]="disabled"
                  (click)="resendOTP()">Resend OTP</button>
              </div>
              <div *ngIf="
                  !registerForm5.controls['otp'].valid &&
                  registerForm5.get('otp')?.touched
                ">
                <small class="text-danger">OTP is required</small>
              </div>
            </div>
            
            <!-- new -->
            <div *ngIf="ibdic" class="createAccount__inputSection" style="margin-top: -26px;">
              <div class="createAccount__inputLabel">Mobile Number</div>
              <div class="usersFilter__branchesField">
                <div class="usersFilter__branchWrappar">
                  <input class="usersFilter__input user_input_ibdic" style="
                      border: none;
                      outline: none !important;
                      box-shadow: none !important;
                      border-color: #000 !important;
                      width: 336px;
                    " formControlName="otp" class="" id="mobileNumber"
                    type="text" required />
                </div>
              </div>
            </div>
            <div *ngIf="ibdic" class="createAccount__inputSection"  style="margin-top: -26px;">
              <div class="createAccount__inputLabel">Email</div>
              <div class="usersFilter__branchesField">
                <div class="usersFilter__branchWrappar">
                  <input class="usersFilter__input" style="
                      border: none;
                      outline: none !important;
                      box-shadow: none !important;
                      border-color: #000 !important;
                      width: 336px;
                    " formControlName="email" class="" id="Email"
                    type="text" />
                </div>
              </div>
            </div>
            <div class="createAccount__inputSection" [ngStyle]="ibdic?{'margin-top': '-26px'} : {}">
              <div class="createAccount__inputLabel">Create a password</div>
              <div class="usersFilter__branchesField">
                <div class="usersFilter__branchWrappar">
                  <input class="usersFilter__input" style="
                      border: none;
                      outline: none !important;
                      box-shadow: none !important;
                      border-color: #000 !important;
                      width: 336px;
                    " placeholder="***********" formControlName="password" class="" id="password"
                    type="{{ hide2 ? 'password' : 'text' }}" required />

                  <fa-icon class="usersFilter__searchIcon" [icon]="hide2 ? faEye : faEyeSlash" (click)="hide2 = !hide2"
                    [attr.aria-label]="'Hide2 Password'" [attr.aria-pressed]="hide2"></fa-icon>
                </div>

                <div class="invalid-feedback" *ngIf="
                    !registerForm5.controls['password']?.valid &&
                    registerForm5.controls['password']?.touched
                  ">
                  <small class="text-danger">Password is required</small>
                </div>
              </div>
              <div class="createAccount__error" *ngIf="registerForm5.get('password')?.hasError('pattern')"
                style="max-width:400px;margin-left: 0px;">

                <img src="../../../assets/Images/login/error.svg" />
                <small class="text-muted">Hint- Minimum of 8 characters with a uppercase, lowercase,
                  special character and a number required.</small>
              </div>
            </div>
            <!-- new -->
            <div class="createAccount__inputSection" [ngStyle]="ibdic?{'margin-top': '-26px'} : {}">
              <div class="createAccount__inputLabel">Confirm password</div>
              <div class="usersFilter__branchesField">
                <div class="usersFilter__branchWrappar">
                  <input class="usersFilter__input" style="
                      border: none;
                      outline: none !important;
                      box-shadow: none !important;
                      border-color: #000 !important;
                      width: 336px;
                    " id="repassword" type="{{ hide ? 'password' : 'text' }}" placeholder="***********"
                    formControlName="repassword" class="" [type]="hide ? 'password' : 'text'" required />
                  <fa-icon class="usersFilter__searchIcon" [icon]="hide ? faEye : faEyeSlash" (click)="hide = !hide"
                    [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hide"></fa-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="createAccount__error createAccount__addCredintials" style="margin-bottom: 0px;" *ngIf="
              registerForm5.get('password')?.value !==
                registerForm5.get('repassword')?.value &&
              registerForm5.get('repassword')?.touched
            ">
            Passwords do not match.
          </div>
          <div class="createAccount__error createAccount__addCredintials" *ngIf="errMsg">
            {{ errMsg }}
          </div>
        </div>
        <div class="createAccount__button" style="margin-top: 24.09px;">
          <button class="createAccount__signInButton" [disabled]="registerForm5.invalid" (click)="onVerify5()">
            Submit
          </button>
        </div>
      </div>
      <div *ngIf="ifReg" class="button-row text-center verify-links">
        <p>Registered Already? <a [routerLink]="['/home']">Sign In</a></p>
      </div>
    </form>

    <form *ngIf="showForm3" name="congratsForm" class="p-3">
      <div>
        <div class="cong-reg text-center">
          <i class="fa-regular fa-check"></i>
          <h2>Congratulations!</h2>
          <p>Your registration successfully completed.</p>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center text-center">
        <app-common-button [routerLink]="['/home']" buttonClass="smallbutton text-center"
          buttonLabel="Login"></app-common-button>
      </div>
    </form>
  </div>

  <app-logo [ngStyle]="showForm5 && ibdic?{'margin-top': '56px'} : {}"></app-logo>

</div>
</div>