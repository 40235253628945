<div class="user-management-bulkList__users-main">

    <div class="user-management-bulkList__users-wrapper">
        <div class="wrapper__forPaginationDivision">
            <div class="user-management-bulkList__users-head">
                <div class="user-management-bulkList__users-title">Bulk Upload Users</div>
                <div (click)="onCloseIconClick()" class="user-management-bulkList__users-close-image"><img src="../../../../assets/Images/user-management/Cross.svg" alt="close"></div>
            </div>
            <div class="user-management-bulkList__records-status">
                <div class="user-management-bulkList__records-status-data">
                    <div class="user-management-bulkList__records-status-name">
                        Users.XLSX
                    </div>
                    <div class="user-management-bulkList__records-imports-errors">
                        <div class="user-management-bulkList__records-importedData">130 records imported</div>
                        <div class="user-management-bulkList__records-notImportedData"><img src="../../../../assets/Images/user-management/error.svg" alt="error"> 2 records with errors will not be imported</div>
                    </div>
                </div>
                <app-common-button buttonClass="smallbutton" buttonLabel="Reupload"></app-common-button>
            </div>
            <div class="user-management-bulkList__bulkData-table">
                <table class="user-management-bulkList__users-table-headings">
                    <thead>
                        <th class="user-name">Name</th>
                        <th class="user-email">Email ID</th>
                        <th class="user-number">Mobile number</th>
                        <th class="user-roles">Roles</th>
                        <th class="user-branches">Branches</th>
                    </thead>
                </table>
                <app-bulk-list-common *ngFor="let value of bulkData" [bulktableData]="value"></app-bulk-list-common>
    
            </div>
        </div>

        <div class="user-management-bulkList__users-page-ination">

            <p-paginator [first]="1" [rows]="10" [totalRecords]="120" [showFirstLastIcon]="false" showEllipsis="true" styleClass="paginator"></p-paginator>

        </div>
    </div>
    <div class="user-management-bulkList__users-action-buttons">
        <app-common-button buttonClass="smallbutton" buttonLabel="Cancel"></app-common-button>
        <app-common-button buttonClass="smallFilledButton" buttonLabel="Import"></app-common-button>
    </div>
</div>