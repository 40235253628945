import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-description-field',
  templateUrl: './description-field.component.html',
  styleUrls: ['./description-field.component.scss']
})
export class DescriptionFieldComponent {

  @Input() DescriptionData:any;
  @Input() control : any;
}
