import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
    selector: '[alphabets]'
})
export class AlphabetsDirective {
    private _formControl: AbstractControl;

    @Input()
    set formControl(control: AbstractControl) {
        this._formControl = control;
    }
    
    inputValue: any = [];
    regex = /^[A-z \d_@.#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]*$/
    
    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event'])
    onInputChange(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;
       
        if (this.regex.test(this.el.nativeElement.value)) {
            if (this.inputValue.length > 0) {
                this.inputValue.splice(0, 1);
                this.inputValue.push(input.value);
            } else {
                this.inputValue.push(input.value);
            }
        } else {
            this._formControl.setValue(this.inputValue[0]);
        }
    }
}
