import { Component, OnInit } from '@angular/core';

interface verify {
  name: string;
  id_label: string;
  id_value: string;
  date_label: string;
  date_value: string;
  verification: string;
  icon: string;
  status: boolean;
}

@Component({
  selector: 'app-global-setting-preview',
  templateUrl: './global-setting-preview.component.html',
  styleUrls: ['./global-setting-preview.component.scss']
})
export class GlobalSettingPreviewComponent implements OnInit {
  verifiedData: Array<verify> = []

  ngOnInit() {
    this.verifiedData = [
      {
        name: 'CIN Details',
        id_label: 'CIN Number:',
        id_value: 'U72900TG2022PTC169517',
        date_label: 'Incorporation Date:',
        date_value: '22/12/2022',
        verification: 'Verified',
        icon: '../../../assets/Images/global-setting/verified.svg',
        status: false
      },
      {
        name: 'GST',
        id_label: 'GST Number:',
        id_value: 'U72900TG2022PTC169517',
        date_label: 'Registration Date:',
        date_value: '22/12/2022',
        verification: 'Verified',
        icon: '../../../assets/Images/global-setting/verified.svg',
        status: false
      },
      {
        name: 'IEC',
        id_label: 'IEC Number:',
        id_value: 'AAFCI5126H',
        date_label: '',
        date_value: '',
        verification: 'Unverified',
        icon: '../../../assets/Images/global-setting/Unverified.svg',
        status: true
      }
    ]
  }
}
