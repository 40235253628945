<button [ngClass]="disabled ? 'disabledBtn' : buttonClass" type="{{type}}" [disabled]="disabled"
  (click)="onClickEvent($event)">
  <span *ngIf="icon">
    <!-- <fa-icon [icon]="icon"></fa-icon>&nbsp; -->
    <img src="{{icon}}" width="24">&nbsp;
  </span>
  <span *ngIf="!loadingIcon">
    {{buttonLabel}}
  </span>
  <span *ngIf="loadingIcon">
    <fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon>
  </span>
</button>