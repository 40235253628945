import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginLayoutComponent } from './layouts/login-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeModule } from './home/home.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpInterceptorInterceptor } from './share/services/interceptor/http-interceptor.interceptor';
import { SharedModule } from './share/shared.module';
import { FormsModule } from '@angular/forms';
import { RedirectionFailureComponent } from './redirection-failure.component';
import { RedirectionSuccessComponent } from './redirection-success.component';
import { ZoneCardComponent } from './organisation-structure/common/zone-card/zone-card.component';
import { SideNavBarComponent } from './utilities/side-nav-bar/side-nav-bar.component';
import { OrgDetailsCardComponent } from './organisation-structure/common/org-details-card/org-details-card.component';
import { OrgDetailsSecondStepComponent } from './organisation-structure/common/org-details-second-step/org-details-second-step.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PackagesTableCardComponent } from './package-listing/common/packages-table-card/packages-table-card.component';
import { PackagesTypeCardComponent } from './package-listing/common/packages-type-card/packages-type-card.component';
import { GlobalSettingHeaderComponent } from './global-setting/global-setting-header/global-setting-header.component';
import { GlobalSettingPreviewComponent } from './global-setting/global-setting-preview/global-setting-preview.component';
import { OrganisationalDetailsComponent } from './global-setting/organisational-details/organisational-details.component';
import { NgxHierarchyModule } from 'ngx-hierarchy';
import { InfoCardsComponent } from './global-setting/info-cards/info-cards.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { NotifcationSettingsComponent } from './global-setting/notifcation-settings/notifcation-settings.component';
import { UserGlobalSettingComponent } from './setting-and-preferences/user-global-setting/user-global-setting.component';
import { OrganisationDataComponent } from './global-setting/organisation-data/organisation-data.component';
import { UserSettingHeaderComponent } from './setting-and-preferences/user-setting-header/user-setting-header.component';
import { NotificationScreenComponent } from './Notification/notification-screen/notification-screen.component';
import { NotificationCardComponent } from './Notification/common/notification-card/notification-card.component';
import { NotificationCardRowComponent } from './Notification/common/notification-card-row/notification-card-row.component';
import { ZoomSliderComponent } from './utilities/zoom-slider/zoom-slider.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { QuillModule } from 'ngx-quill';
import { SidebarComponent } from './home/sidebar/sidebar.component';
import { CommonBreadcrumComponent } from './utilities/common-breadcrum/common-breadcrum.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginLayoutComponent,
    HomeLayoutComponent,
    HeaderComponent,
    FooterComponent,
    RedirectionFailureComponent,
    RedirectionSuccessComponent,
    ZoneCardComponent,
    SideNavBarComponent,
    OrgDetailsCardComponent,
    OrgDetailsSecondStepComponent,
    PackagesTableCardComponent,
    PackagesTypeCardComponent,
    GlobalSettingPreviewComponent,
    InfoCardsComponent,
    MyProfileComponent,
    NotifcationSettingsComponent,
    UserGlobalSettingComponent,
    OrganisationDataComponent,
    UserSettingHeaderComponent,
    NotificationScreenComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    HomeModule,
    FontAwesomeModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TableModule,
    PaginatorModule,
    OverlayPanelModule,
    MultiSelectModule,
    CalendarModule,
    NgxHierarchyModule,
    HttpClientModule,
    NgCircleProgressModule.forRoot(),
    QuillModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
