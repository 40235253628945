<!-- Below ngclass validation used for the UI purpose, not correct validations -->
<tr class="bulkListTable__rowMain" [ngClass]="{'bulkListTable__rowMainred': !bulktableData.name || bulktableData.email==='19520102' || !bulktableData.number || !bulktableData.roles || bulktableData.branches==='4 branches' }">
    <td class="bulkListTable__name">
        <div *ngIf="bulktableData.name!=''">{{bulktableData.name}}</div>
        <!-- If the data displayed is invalid or empty, we can edit the data (validations are not given)-->
        <div *ngIf="bulktableData.name===''" class="error-and-input"><img src="../../../../assets/Images/user-management/error.svg" alt="invalid" class="error-image"> <input [ngModel]="bulktableData.name" type="text" class="form-control" [value]="bulktableData.name"></div> 
    </td>
    <td class="bulkListTable__email">
        <div *ngIf="bulktableData.email!='19520102'">{{bulktableData.email}}</div>
        <div *ngIf="bulktableData.email==='19520102'" class="error-and-input"><img src="../../../../assets/Images/user-management/error.svg" alt="invalid" class="error-image"> <input [ngModel]="bulktableData.email" type="email" class="form-control"></div> 
    </td>
    <td class="bulkListTable__mobile-number">
        <div *ngIf="bulktableData.number!=''">{{bulktableData.number}}</div>
        <div *ngIf="bulktableData.number===''" class="error-and-input"><img src="../../../../assets/Images/user-management/error.svg" alt="invalid" class="error-image"> <input [ngModel]="bulktableData.number" type="text" class="form-control"></div> 
    </td>
    <td class="bulkListTable__roles">
        <div *ngIf="bulktableData.roles!=''">{{bulktableData.roles}}</div>
        <div *ngIf="bulktableData.roles===''" class="error-and-input"><img src="../../../../assets/Images/user-management/error.svg" alt="invalid" class="error-image"> <input [ngModel]="bulktableData.roles" type="text" class="form-control"></div> 
    </td>
    <td class="bulkListTable__branches">
        <div *ngIf="bulktableData.branches!='4 branches'">{{bulktableData.branches}}</div>
        <div *ngIf="bulktableData.branches==='4 branches'" class="error-and-input"><img src="../../../../assets/Images/user-management/error.svg" alt="invalid" class="error-image"> <input [ngModel]="bulktableData.branches" type="text" class="form-control"></div> 
    </td>
</tr>