import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-common-package-header',
  templateUrl: './common-package-header.component.html',
  styleUrls: ['./common-package-header.component.scss']
})
export class CommonPackageHeaderComponent {
  @Input() createPackageHeader: string;
  @Input() draftId: string;
  @Input() packageId: string;
  @Input() draftStatus: string;
  @Output() saveAsDraft = new EventEmitter<any>();

  sendToDrafts(){
    this.saveAsDraft.emit()
  }
}
